import React from "react";
import { Helmet } from "react-helmet-async";
import withAELayout from "../../../components/ae.desktop/layout";
import Faq from "../../../components/ae.desktop/faq";
import ErrorHandler from "../../../components/shared/with-error-handler";
import { appUrl } from "../../../constants/url-constants";

const FAQ = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>FAQs - CARS24 UAE: Your Questions Answered</title>
                <meta name="description" content="Find answers to all your queries about buying, financing, and the documentation process at CARS24 UAE. Learn about our services, 7-day trial, and more on our FAQ page." />
                <meta name="ROBOTS" content="INDEX,FOLLOW" />
                <link href={`${appUrl()}/faq/`} rel="canonical" />
            </Helmet>
            <ErrorHandler>
                <Faq />
            </ErrorHandler>
        </React.Fragment>
    );
};

export default withAELayout(FAQ);
