import React from "react";
import withAELayout from "../../../components/ae.desktop/layout";
import { Helmet } from "react-helmet-async";
import AboutUsAeContent from "../../../components/shared/about-us-ae-content";

// Constants
import { appUrl } from "../../../constants/url-constants";

const AboutUs = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>CARS24 - Know about car buying platform in UAE</title>
                <meta name="description" content={"CARS24 is a fast growing auto-tech company to buy and sell used cars. Check out our verified cars at your nearest CARS24 branch."} />
                <meta name="ROBOTS" content="INDEX,FOLLOW" />
                <link href={`${appUrl()}/about-us/`} rel="canonical" />
            </Helmet>
            <AboutUsAeContent />
        </React.Fragment>
    );
};

export default withAELayout(AboutUs);
