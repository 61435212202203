/* eslint-disable complexity */
import React, { useState } from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";
import LazyImage from "../../shared/lazy-image";

// Images
import OrangeTickShield from "./images/orange-tick-icon.png";
import BlueTickShield from "./images/blue-shield-tick.png";
import PinkTickShield from "./images/pink-shield-tick.png";
import GreenTick from "./images/green-tick.svg";
import RedCrossIcon from "./images/red-cross.svg";
import BlueInfoIcon from "./images/blue-info.png";

// components
import ViewMoreServicesPopup from "../servicing-view-more-services-popup";

// constants

// tracking

const services = {
    "minor": "minor",
    "major": "major",
    "doorstep": "doorstep"
};

const serviceBackground = {
    [services.doorstep]: "#FCEFFE",
    [services.major]: "#FFF7EB",
    [services.minor]: "#E8F6FF"
};

const serviceBackgroundImage = {
    [services.doorstep]: PinkTickShield,
    [services.major]: OrangeTickShield,
    [services.minor]: BlueTickShield
};

const ServiceDetailsCard = ({ serviceInfo = {} }) => {

    const {
        serviceId,
        exclusions = [],
        serviceName = "",
        timeline = "",
        odometer = "",
        checkpointsCount,
        serviceCategoriesList = []
    } = serviceInfo || {};

    const inclusionServices = [];

    serviceCategoriesList.forEach(serviceCategory => {
        const { jobs } = serviceCategory || {};
        inclusionServices.push(jobs[0]?.jobDescription);
    });

    const [showViewMorePopup, setShowViewMorePopup] = useState(false);

    const onViewMoreClick = () => {
        setShowViewMorePopup(true);
    };

    const onViewMoreClose = () => {
        setShowViewMorePopup(false);
    };

    return (
        <React.Fragment>
            <div styleName={"styles.serviceCardWrapper"} >
                <div styleName={"styles.header"} style={{ background: serviceBackground[serviceId] }} >
                    <div>
                        {serviceName && <p styleName={"styles.serviceType"} >{serviceName}</p>}
                        <p styleName={"styles.inpectionPoints"} >{`${checkpointsCount} point inspection`}</p>
                    </div>
                     <div styleName={"styles.tickIcon"} >
                        <LazyImage src={serviceBackgroundImage[serviceId]} />
                    </div>
                </div>
                <div styleName={"styles.serviceCardBody"}  >
                    {odometer && <div>
                        <p styleName={"styles.itemTitle"} >Service Scheduled</p>
                        <p styleName={"styles.itemValue"} >{odometer}</p>
                    </div>}
                    {timeline && <div>
                        <p styleName={"styles.itemTitle"} >Timeline</p>
                        <p styleName={"styles.itemValue"} >{timeline}</p>
                    </div>}
                    <div styleName={"styles.separatorLine"} />
                    {inclusionServices?.length !== 0 && <div styleName={`${exclusions ? "styles.inclusionWrapper" : "styles.inclusionWrapperWithNoExclusions"}`}>
                        <p styleName={"styles.itemTitle"} >{`${checkpointsCount} Service Parameters`}</p>
                        <div styleName={"styles.itemValueTags"} >
                            {(inclusionServices || []).map(inclusionService => {
                                return (<div styleName={"styles.tagItem"} key={`${serviceId}_${inclusionService}`} >
                                    <LazyImage src={GreenTick} />
                                    <span styleName={"styles.tagText"} >{inclusionService}</span>
                                </div>);
                            })}
                        </div>
                    </div>}
                    {exclusions?.length > 0 && <div>
                        <p styleName={"styles.itemTitle"} >Exclusions</p>
                        <div styleName={"styles.itemValueTags styles.mb-0"} >
                            {
                                (exclusions || []).map(exclusion => {
                                    return (
                                        <div styleName={"styles.tagItem"} key={exclusion} >
                                            <LazyImage src={RedCrossIcon} />
                                            <span styleName={"styles.tagText"} >{exclusion}</span>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>}
                    <div styleName={"styles.viewMore"} onClick={onViewMoreClick}>
                        View more <span> <LazyImage src={BlueInfoIcon} width={12} height={14} /></span>
                    </div>
                    {
                        showViewMorePopup && <ViewMoreServicesPopup onClose={onViewMoreClose} />
                    }
                </div>
            </div>
        </React.Fragment>
    );
};

ServiceDetailsCard.propTypes = {
    serviceInfo: PropTypes.object
};

export default ServiceDetailsCard;
