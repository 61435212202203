import React from "react";
import { Helmet } from "react-helmet-async";
import { appUrl } from "../../../constants/url-constants";
import ErrorHandler from "../../../components/shared/with-error-handler";
import withLayout from "../../../components/ae.desktop/layout";
import SiteMapInfo from "../../../components/ae.desktop/sitemap-info";

const SiteMap = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>
                    HTML Sitemap - CARS24
                </title>
                <meta name="description"
                    content="CARS24 sitemap - An easy access to all the important web
                     pages you wish to find on CARS24. Visit this page to get the
                      complete structure of the site." />
                <link href={`${appUrl()}/sitemap/`} rel="canonical" />
                <meta name="keywords" content="HTML sitemap, CARS24 sitemap" />
            </Helmet>
            <ErrorHandler>
                <SiteMapInfo />
            </ErrorHandler>
        </React.Fragment>
    );
};

export default withLayout(SiteMap);
