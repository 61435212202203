import { cfAuthKey } from "../constants/url-constants";

/* eslint-disable max-statements */
export default (api) => {

    const getUserCFDetails = (token) => {
        return api.get(`/v1/user/quote/price`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const createUserCFDetails = (payload, token) => {
        return api.post(`/v1/user/quote/price`, { ...payload }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const updateUserCFDetails = (payload, token) => {
        return api.put(`/v1/user/quote/price`, { ...payload }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const fetchPriceBenefit = (vehicleId, params) => {
        return api.get(`/v1/price-benefits?appointmentId=${vehicleId}`, { params });
    };

    const getCarCfDetails = (appointmentId, token) => {
        return api.get(`/v1/order/quote/price/${appointmentId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const createCarCfDetails = (params, token) => {
        return api.post(`/v1/order/quote/price/${params.appointmentId}`, { ...params }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const updateCarCfDetails = (params, token) => {
        return api.put(`/v1/order/quote/price/${params.appointmentId}`, { ...params }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const fetchFinanceDetailAE = (vehiclePrice, carYear, financeEligibility) => {
        return api.get(`/v1/client/default/emi/calculationV2?vehiclePrice=${vehiclePrice}&carYear=${carYear}${financeEligibility ? `&financeType=${financeEligibility}` : ""}`, {
            headers: {
                X_API_KEY: cfAuthKey()
            }
        });
    };

    const fetchInsuranceCompareDetails = (keys, appointmentId, token) => {
        return api.get(`/v1/vas/insurance?key=${keys}&appId=${appointmentId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const getEmiDetails = (token) => {
        return api.get(`/v1/vas/config`, (token && {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }));
    };

    const fetchLoanPreApprovalDetail = ({ secureToken, orderId }) => {
        return api.get(`/v1/order/${orderId}/loan-approval`, {
            headers: {
                Authorization: `Bearer ${secureToken}`
            }
        });
    };

    const fetchDropdownOptions = ({ secureToken = "", dropdowntype = "", parentId = "" }) => {
        return api.get(`/v1/user-detail/dropdown/${dropdowntype}${parentId ? `?parentId=${parentId}` : ""}`, {
            headers: {
                Authorization: `Bearer ${secureToken}`
            }
        });
    };

    const postLoanPreApprovalDetail = ({ secureToken, orderId, updatedPayload, isSubmit }) => {
        return api.post(`/v1/order/${orderId}/loan-approval${isSubmit ? "/submit" : ""}`, updatedPayload && { ...updatedPayload }, {
            headers: {
                Authorization: `Bearer ${secureToken}`
            }
        });
    };

    const submitLoanPreApprovalDetail = ({ secureToken, orderId }) => {
        return api.post(`/v1/order/${orderId}/loan-approval/submit`, {
            headers: {
                Authorization: `Bearer ${secureToken}`
            }
        });
    };

    const getServiceContractList = (make, model, token) => {
        return api.get(`/v1/tof/service-contract?make=${make}&model=${model}`, (token && {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }));
    };

    const getServiceDescription = (token) => {
        return api.get("/v1/tof/service-description", (token && {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }));
    };
    const getServiceDescriptionV2 = (token) => {
        return api.get("/v2/tof/service-description", (token && {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }));
    };

    const updateEmiCalculator = (params) => {
        return api.put(`/v1/client/quote/price/calculation`, { ...params }, {
            headers: {
                X_API_KEY: cfAuthKey()
            }
        });
    };

    const checkLoanEligibility = (token, params) => {
        return api.get("/v1/client/loan/eligibility", {
            headers: token ?  {
                Authorization: `Bearer ${token}`
            } : {},
            params
        });
    };

    const checkUserLoanEligibility = (token, params) => {
        return api.get("/v1/client/user/loan/eligibility", {
            headers: token ?  {
                Authorization: `Bearer ${token}`
            } : {},
            params
        });
    };

    return {
        getUserCFDetails,
        createUserCFDetails,
        updateUserCFDetails,
        getEmiDetails,
        getCarCfDetails,
        createCarCfDetails,
        updateCarCfDetails,
        fetchFinanceDetailAE,
        fetchInsuranceCompareDetails,
        fetchPriceBenefit,
        // Loan Pre-Approval
        fetchLoanPreApprovalDetail,
        postLoanPreApprovalDetail,
        submitLoanPreApprovalDetail,
        fetchDropdownOptions,
        getServiceContractList,
        getServiceDescription,
        updateEmiCalculator,
        checkLoanEligibility,
        checkUserLoanEligibility,
        getServiceDescriptionV2
    };
};
