import Types from "./types";
import { ListingService } from "../../../service";

const fetchRecentlyViewedCarListSuccess = (data) => ({
    type: Types.FETCH_RECENTLY_VIEWED_SUCCESS,
    data
});

const fetchRecentlyViewedCarListFailure = (error) => ({ type: Types.FETCH_RECENTLY_VIEWED_FAILURE, error });

// eslint-disable-next-line no-unused-vars
const fetchRecentlyViewedCarList = (recentlyViewedCars) => (dispatch, getState) => {
    const { user: { secureToken = "" } } = getState() || {};

    return new Promise((resolve, reject) => {
        ListingService.fetchListing({
            sf: `appointmentId:${recentlyViewedCars.join(";")}`,
            size: 5,
            page: 0
        }, "", "v3", secureToken).then(response => {
            dispatch(fetchRecentlyViewedCarListSuccess(response.data));
            resolve(response.data.data);
        }).catch(error => {
            dispatch(fetchRecentlyViewedCarListFailure(error));
            reject(error);
        });
    });
};

export {
    fetchRecentlyViewedCarList
};
