import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BudgetCars from "./component.js";

const mapStateToProps = ({ config: { budgetSection } = {} }) => ({ budgetSection });

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BudgetCars);
