/* eslint-disable react/no-unknown-property */
/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import FacebookWhiteIcon from "../../shared/icon-social/images/facebook-white.svg";
import TwitterWhiteIcon from "../../shared/icon-social/images/twitter-white.svg";
import InstagramWhiteIcon from "../../shared/icon-social/images/instagram-white.svg";
import YoutubeWhiteIcon from "../../shared/icon-social/images/youtube-white.svg";
import LinkedinWhiteIcon from "../../shared/icon-social/images/linkedin-white.svg";

import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import { DOWNLOAD_APP_URL, TWITTER_URL, INSTAGRAM_URL, YOUTUBE_URL, LINKEDIN_URL, FACEBOOK_URL, DOWNLOAD_APP_URL_IOS, NUMBER } from "../../../constants/app-constants";
import SocialIcon from "../../shared/icon-social";
import LazyImage from "../../shared/lazy-image";
import appStore from "../../shared/icon-social/images/app-store-new.svg";
import scrollToTop from "../../../utils/helpers/scroll-to-top";

const FooterV3 = (props) => {
    const { footerSection } = props || {};

    const onAnchorClick = (e) => {
        scrollToTop();
        trackDesktopCustomEventsAE("footerClick", {
            eventlabel: e.target.getAttribute("eventlabel") || "NA"
        });
    };

    return (
        <footer styleName={"styles.footer"}>
            <div className={"container"}>
                <div className={"row"} styleName={"styles.rowSpace"}>
                    <div className={"col"}>
                        <div styleName={"styles.colWidth"}>
                            <h2 styleName={"styles.heading"}>BETTER DRIVES, BETTER LIVES</h2>
                            <h3 styleName={"styles.subHeading"}>KEEP IN TOUCH</h3>
                            <ul styleName={"styles.socialMedia"}>
                                <li>
                                    <a href={FACEBOOK_URL} target="_blank"><LazyImage className={"img-fluid"} src={FacebookWhiteIcon} alt="Facebook" /></a>
                                </li>
                                <li>
                                    <a href={TWITTER_URL} target="_blank"><LazyImage className={"img-fluid"} src={TwitterWhiteIcon} alt="Twitter" /></a>
                                </li>
                                <li>
                                    <a href={INSTAGRAM_URL} target="_blank"><LazyImage className={"img-fluid"} src={InstagramWhiteIcon} alt="Instagram" /></a>
                                </li>
                                <li>
                                    <a href={YOUTUBE_URL} target="_blank"><LazyImage className={"img-fluid"} src={YoutubeWhiteIcon} alt="Youtube" /></a>
                                </li>
                                <li>
                                    <a href={LINKEDIN_URL} target="_blank"><LazyImage className={"img-fluid"} src={LinkedinWhiteIcon} alt="Linkedin" /></a>
                                </li>
                            </ul>
                            <h3 styleName={"styles.headingApp"}>Experience CARS24 App on Mobile</h3>
                            <div styleName={"styles.footerlinkApp"}>
                                <a href={DOWNLOAD_APP_URL_IOS} target="" styleName={"styles.iconPlay"}>
                                    <LazyImage src={appStore} />
                                </a>
                                <a href={DOWNLOAD_APP_URL} target="" styleName={"styles.iconPlay"}>
                                    <SocialIcon name="googlePlay" />
                                </a>
                            </div>
                            <div styleName={"styles.footerCopyrightText"}>
                                <p>© 2019  www.cars24.com</p>
                                <p>All rights reserved</p>
                            </div>
                        </div>
                    </div>
                    {(footerSection || [])?.map((footerItem = {}, index) => {
                        if (index === NUMBER.TWO || index === NUMBER.THREE) {
                            if (index === NUMBER.THREE) {
                                return (<></>);
                            }
                            const nextItem = footerSection?.[NUMBER.THREE];
                            return (
                                <div className={"col"} key={`footer_items_${footerItem?.order}`}>
                                    <h3 styleName={"styles.secondHeading"}>{footerItem?.title || ""}</h3>
                                    <ul styleName={"styles.footerLink"}>
                                        {footerItem?.items?.map((footerSubItems = {}, footerIndex) => {
                                            const { redirectionUrl } = footerSubItems || {};
                                            const target = footerSubItems?.title === "Blog" ? "_blank" : "";
                                            return (
                                                <li key={`footer_link_${footerItem?.order}_${footerIndex}`}>
                                                <a
                                                    onClick={onAnchorClick}
                                                    href={redirectionUrl}
                                                    eventlabel={footerSubItems?.title}
                                                    target={target}
                                                >
                                                    {footerSubItems?.title}
                                                </a>
                                            </li>);
                                            }
                                        )}
                                    </ul>
                                    <h3 styleName={`styles.secondHeading styles.secondFooterItem`}>{nextItem?.title || ""}</h3>
                                    <ul styleName={"styles.footerLink"}>
                                        {nextItem?.items?.map((footerSubItems = {}, footerIndex) => {
                                            const { redirectionUrl, title } = footerSubItems || {};
                                            return (
                                                <li key={`footer_link_${nextItem?.order}_${footerIndex}`}>
                                                <a
                                                    onClick={onAnchorClick}
                                                    href={redirectionUrl}
                                                    eventlabel={title}
                                                >
                                                    {title}
                                                </a>
                                            </li>);
                                            }
                                        )}
                                    </ul>
                                </div>
                            );
                        } else {
                            return (
                                <div className={"col"} key={`footer_items_${footerItem?.order}`}>
                                    <h3 styleName={"styles.secondHeading"}>{footerItem?.title || ""}</h3>
                                    <ul styleName={"styles.footerLink"}>
                                        {footerItem?.items?.map((footerSubItems = {}, footerIndex) => {
                                            const { redirectionUrl } = footerSubItems || {};
                                            const target = ["India", "Australia"].includes(footerSubItems?.title) ? "_blank" : "";
                                            return (
                                                <li key={`footer_link_${footerItem?.order}_${footerIndex}`}>
                                                <a
                                                    onClick={onAnchorClick}
                                                    href={redirectionUrl}
                                                    eventlabel={footerSubItems?.title}
                                                    target={target}
                                                >
                                                    {footerSubItems?.title}
                                                </a>
                                            </li>);
                                            }
                                        )}
                                    </ul>
                                </div>
                            );
                        }
                    }
                    )}
                </div>
            </div>
        </footer>
    );
};

FooterV3.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    cityList: PropTypes.array
};

export default FooterV3;
