export default (api) => {
    const fetchPageContent = (pageSlug) => {
        return api.get(`api/v1/bff-schema/page/page-template/${pageSlug}`, {
            headers: {
                "auth-token": process.env.CMS_AUTH_TOKEN
            }
        });
    };

    return {
        fetchPageContent
    };
};
