import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Component
import LazyImage from "../lazy-image";

const ButtonImage = ({ data, onClick }) => {
    const {
        text,
        imgSrc,
        width,
        height,
        showLazy = true,
        imgAltTag,
        disableOnClick = false
    } = data;
    return (
        <div onClick={onClick} styleName={`styles.wrapper ${disableOnClick ? "styles.disableOnClick" : ""}`} className="buttonWrapper">
            <button>
                {showLazy ? <LazyImage src={imgSrc} alt={imgAltTag || text} width={width} height={height} /> :
                    <span><img src={imgSrc} alt={imgAltTag || text} width={width} height={height} /></span>
                }
                {!!text &&  <p dangerouslySetInnerHTML={{ __html: text }} />}
            </button>
        </div>
    );
};

ButtonImage.propTypes = {
    data: PropTypes.object.isRequired,
    onClick: PropTypes.func

};

export default ButtonImage;
