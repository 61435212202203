import React from "react";
import withAELayout from "../../../components/ae.desktop/layout";
import { Helmet } from "react-helmet-async";
import TermsInfoAe from "../../../components/shared/terms-info-ae";

// Constants
import { appUrl } from "../../../constants/url-constants";

const TermsOfUse = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>CARS24 UAE | Terms of Use</title>
                <meta name="description" content={"Explore CARS24 UAE's Terms of Use, detailing user rights, obligations, and conditions for website access and services, ensuring informed usage and interactions."} />
                <meta name="ROBOTS" content="INDEX,FOLLOW" />
                <link href={`${appUrl()}/terms-of-use/`} rel="canonical" />
            </Helmet>
            <TermsInfoAe />
        </React.Fragment>
    );
};

export default withAELayout(TermsOfUse);
