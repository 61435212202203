/* eslint-disable max-params */
export default (api) => {

    const submitServicingLead = (mobileNumber, leadSource) => {
        return api.post(`campaign/servicing/create-lead`, {mobileNumber, leadSource});
    };

    return {
        submitServicingLead
    };
};
