/* eslint-disable max-params */
/* eslint-disable complexity */
import React, { useEffect } from "react";
// import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Component
import ButtonImage from "../../shared/button-image";
// import ListTab from "../../shared/list-tab";

// Images
import DefaultBodyType from "../../shared/images/filter-body-type/default.svg";

// Constants
import { getPopularBrandUrl } from "../../../utils/helpers/cdn-image-url";
import { FILTER_ENTITIES } from "../../../utils/filters-v2/constants";
import { FILTER_DESCRIPTION_MAP, HOME_BRAND_LIMIT } from "../used-cars-catagory/constant";

// Utilities
// import scrollToTop from "../../../utils/helpers/scroll-to-top";
// import Filters from "../../../utils/filters-v2";

// Tracking
// import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import { NUMBER } from "../../../constants/app-constants";
import SlickWrapper from "../../shared/slick-wrapper";
import { capitalizeFirstLetter } from "../../../utils/helpers/title-to-upperCase";

const settings = {
    slidesToShow: 10,
    slidesToScroll: 1,
    arrow: true,
    dots: false,
    infinite: false,
    gap: 8
};

const ServicingBrands = ({
    filterTypes = [FILTER_ENTITIES.MAKE],
    allFilters,
    isFilterSSR,
    updateFilterSSRStatusConnect,
    fetchFiltersConnect
}) => {

    const renderImageButton = (filter, optionCards, { imageMapping = {}, defaultImage }) => (
        (optionCards || []).map((item, index) => {
            return (
                <div key={index}
                    styleName={`styles.${filter?.key}Wrap`}
                >
                    <ButtonImage
                        data={{
                            text: item?.displayText?.length === NUMBER.THREE ? item?.displayText?.toUpperCase() : capitalizeFirstLetter(item?.displayText?.toLowerCase()),
                            imgSrc: [FILTER_ENTITIES.BODY_TYPE, FILTER_ENTITIES.CATEGORY].includes(filter?.key) ? (imageMapping[item?.key] || defaultImage || DefaultBodyType) : getPopularBrandUrl(item?.key),
                            disableOnClick: true
                        }}
                    />
                </div>
            );
        })
);

    const renderList = (selectedFilter = {}, filterType) => {
        let component = null;
        const optionCards = (selectedFilter?.options || []).slice(0, HOME_BRAND_LIMIT);

        // Doing this to show limited brands
        component = (
            <div styleName={`styles.brandSliderWrapper styles.brandSliderWrapper2 styles.visible`}>
                <SlickWrapper {...settings}>
                    {renderImageButton(selectedFilter, optionCards, FILTER_DESCRIPTION_MAP[filterType])}
                </SlickWrapper>
            </div>
        );
        return component;
    };

    useEffect(() => {
        if (!isFilterSSR) {
            try {
                const filterParams = { variant: "filterV5" };
                fetchFiltersConnect(filterParams);
            } catch (err) {
                // Handle if any error
            }
        }
        updateFilterSSRStatusConnect(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section styleName={"styles.servicingBrandsCtn"}>
            <div styleName={"styles.wrapper"} className="container">
                <div styleName={"styles.titleWrapper"} >
                    <p styleName={"styles.mainTitle"} >Car brands we service</p>
                    <p styleName={"styles.subTitle"} >Check out all the popular brands</p>
                </div>
                <div>
                    {
                        filterTypes.map((item, index) => {
                            const selection = allFilters[filterTypes[index]] || {};
                            return renderList(selection, filterTypes[index]);
                        })
                    }
                </div>
            </div>
        </section>
    );
};

ServicingBrands.propTypes = {
    filterTypes: PropTypes.array,
    allFilters: PropTypes.object,
    isFilterSSR: PropTypes.bool,
    updateFilterSSRStatusConnect: PropTypes.func,
    fetchFiltersConnect: PropTypes.func,
    updateFilterOptionDataConnect: PropTypes.func
};

export default ServicingBrands;
