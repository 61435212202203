import React from "react";

// Styles
import styles from "./styles.css";
import RightArrow from "./images/right-arrow.svg";
import LazyImage from "../../shared/lazy-image";

const BudgetCars = (props) => {
    const { budgetSection } = props || {};
    const { items = [] } = budgetSection || {};

    return (
        <div styleName={"styles.budgetCarContainer"} >
            <h2 styleName={"styles.title"} >{budgetSection?.title}</h2>
            <div styleName={"styles.budgetOptions"}>
                {items?.map(({title, redirectionUrl}, index) => (
                    <div
                        key={`budget_cars_${index}`}
                        styleName={"styles.budgetTile"}
                    >
                        <a href={redirectionUrl}>
                            {title}
                        </a>
                        <LazyImage className={"img-fluid"} src={RightArrow} alt="Arrow" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BudgetCars;
