import React, { useState } from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Icons
import ArrowIcon from "../../shared/arrow";

// Constants
import { DEFAULT_AE_CITY_NAME } from "../../../constants/app-constants";
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";

const FaqSection = ({
    selectedCityName = DEFAULT_AE_CITY_NAME,
    faqs = [],
    onViewAllCallback = () => { },
    onFaqExpandCallback = () => { },
    title = ""
}) => {

    const { customPush } = useCustomHistoryOperations();

    const [isOpen, setIsOpen] = useState({ 0: true });

    const onClick = (index, question) => {
        setIsOpen((state) => {
            if (!state[index]) {
                onFaqExpandCallback(question, index);
            }
            return { [index]: !state[index] };
        });
    };

    const onClickViewMore = async () => {
        onViewAllCallback();
        await customPush(`/faq/`);
    };

    return (
        <div className="container">
            <div styleName={"styles.faqSection"} className="newDlsFaqSection">
                <div styleName={"styles.headerCtn"} className="newDlsHeader">
                    {title ? <h2>{title}</h2> : <h2>FREQUENTLY ASKED QUESTIONS FOR USED CARS IN {selectedCityName?.toUpperCase()}</h2>}
                    <span styleName={"styles.viewAllBtn"} onClick={onClickViewMore}>
                        VIEW ALL FAQS
                    </span>
                </div>
                {(faqs || [])?.map((questionItem, index) => {
                    const { question = "", answer = "" } = questionItem || {};
                    const rightArrow = 0;
                    const downArrow = -180;
                    return (
                        <div key={index} styleName={"styles.wrap"} className={`${isOpen[index] ? "activeWrap" : "wrap"}`}>
                            <h3 styleName={`styles.title ${isOpen[index] ? "styles.active" : ""}`} onClick={() => onClick(index, question)}>
                                {question}
                                <ArrowIcon black rotateBy={isOpen[index] ? downArrow : rightArrow} />
                            </h3>
                            {<div styleName={isOpen[index] ? "styles.content" : "styles.hideContent"}
                                dangerouslySetInnerHTML={{ __html: answer }}
                            />}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

FaqSection.propTypes = {
    selectedCityName: PropTypes.string,
    faqs: PropTypes.array,
    onViewAllCallback: PropTypes.func,
    onFaqExpandCallback: PropTypes.func,
    title: PropTypes.string
};

export default FaqSection;
