import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_CITY_LIST_SUCCESS
    FETCH_CONFIG_SUCCESS
    FETCH_BOOKING_COUNT

    FETCH_COUPON_LIST_INIT
    FETCH_COUPON_LIST_SUCCESS
    FETCH_COUPON_LIST_FAILURE

    UPDATE_SALE_DATA
    UPDATE_SEO_DATA

    UPDATE_SUPER_APP_CONFIG
    FETCH_RATINGS
`,
    {
        prefix: "configssr/"
    }
);
