import React from "react";
import PropTypes from "prop-types";
import SlickWrapper from "./../../shared/slick-wrapper";
import { Helmet } from "react-helmet-async";

// Styles
import styles from "./styles.css";

// Utils
import { fastlyUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";

// Constants
import { FASTLY_BASE_URL, NUMBER } from "../../../constants/app-constants";
import TrackableRecentlyAddedCarCardItem from "../trackable-recently-added-car-item";
import SmallerCarCard from "../smaller-car-card";

const RecentlyCarSectionSlider = ({
    carList = [],
    handleCarClick = () => { },
    onSwipeCallBack = () => { },
    preloadNumber = NUMBER.ZERO,
    sectionName = ""
}) => {
    const SLIDER_SETTINGS = {
        slidesToShow: 1.9,
        slidesToScroll: 1.1,
        infinite: false,
        arrow: false,
        dots: false,
        gap: 16
    };

    return (
        <div styleName={"styles.recentlySectionSlider"}>
            <div styleName={"styles.carsRow"}>
                <SlickWrapper {...SLIDER_SETTINGS}>
                    {
                        (carList || [])?.slice(0, NUMBER.FIVE)?.map((car, index) => {
                            const { appointmentId } = car || {};
                            const carImgSrc = fastlyUrl({ basePath: `${FASTLY_BASE_URL}/`, path: car?.mainImage?.path, type: imageTypes.aeCarDetailPreview, width: 200 });
                            return (
                                <React.Fragment key={index}>
                                    {index < preloadNumber && preloadNumber && <Helmet>
                                        <link rel="preload" href={carImgSrc} as={"image"} type="image/webp" />
                                    </Helmet>}
                                    <TrackableRecentlyAddedCarCardItem
                                        onVisibleCallback={onSwipeCallBack}
                                        carId={appointmentId}
                                    >
                                        <SmallerCarCard carInfo={car} index={index} handleCarClick={handleCarClick} sectionName={sectionName}/>
                                    </TrackableRecentlyAddedCarCardItem>
                                </React.Fragment>
                            );
                        })
                    }
                </SlickWrapper>
            </div>
        </div>
    );
};

RecentlyCarSectionSlider.propTypes = {
    carList: PropTypes.array,
    handleCarClick: PropTypes.func,
    onSwipeCallBack: PropTypes.func,
    preloadNumber: PropTypes.number,
    sectionName: PropTypes.string
};

export default RecentlyCarSectionSlider;
