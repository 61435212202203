import React, { Fragment } from "react";
import PropTypes from "prop-types";

//styles
import styles from "./styles.css";

//constants
import { NUMBER } from "../../../constants/app-constants";

//images
import ArrowMore from "./images/arrow-more.svg";
import ArrowLess from "./images/arrow-less.svg";

const ExpandableSection = ({ sectionInfo = {}, services = [], isExpanded, onAccordionChange }) => {
    const {
        name,
        checkpoints = []
    } = sectionInfo || {};
    return (
        <Fragment>
            <div styleName={"styles.expandableSectionTitleWrapper"}>
                <div styleName={"styles.expandableSectionTitle"}> {name} </div>
                <div styleName={"styles.accordionText"} onClick={() => onAccordionChange(name)}> {`${isExpanded ? "View less" : "View more"}`} <span><img src={`${isExpanded ? ArrowLess : ArrowMore}`} width={16} height={16}/></span> </div>
            </div>
            { isExpanded && <div styleName={"styles.expandableSectionWrapper"}>
                {
                    (checkpoints || []).map((checkpoint, index) => {
                        const { title, action } = checkpoint;
                        return (<div styleName={`styles.expandableSectionLineItem ${index % NUMBER.TWO !== 0 ? "styles.evenLineItem" : ""}`} key={`${title}_${index}`}>
                            <div styleName={"styles.description"}>
                                {title}
                            </div>
                            <div styleName={"styles.values"}>
                                {(services || []).map(service => {
                                    if (action[service]) {
                                        return (<div styleName={"styles.value"} key={`expandableSection_${title}_${service}`}> {action[service]} </div>);
                                    } else {
                                        return (<div styleName={"styles.value styles.naValue"} key={`expandableSection_${title}_${service}`}> NA </div>);
                                    }
                                })}
                            </div>

                        </div>);
                    })
                }
            </div>}

        </Fragment>
    );
};
ExpandableSection.propTypes = {
    sectionInfo: PropTypes.object,
    services: PropTypes.array,
    isExpanded: PropTypes.bool,
    onAccordionChange: PropTypes.func
};

export default ExpandableSection;
