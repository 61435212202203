import React from "react";
import OpenInMobile from "../../../components/ae.desktop/open-in-mobile";
import withLayout from "../../../components/ae.desktop/layout";
import { Helmet } from "react-helmet-async";

const OpenInMobilePage = ({ ...props }) => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Buy used cars online in UAE </title>
                <meta name="ROBOTS" content="NOINDEX,FOLLOW" />
            </Helmet>
            <OpenInMobile {...props} />
        </React.Fragment>
    );
};

export default withLayout(OpenInMobilePage);
