import React from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";

// Constants
import { appUrl } from "../../../constants/url-constants";
import { DEFAULT_AE_CITY_NAME } from "../../../constants/app-constants";

//Locale
import HomeLocale from "../../../locales/mobile/home";

// Utilities
import dashedLowercase from "../../../utils/helpers/dashed-lowercase";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const MetaTagsCarHome = ({ location }) => {
    const baseUrl = `${appUrl()}/`;
    const { meta: metaLocale } = HomeLocale;
    const queryParams = parseLocationSearchParams(location.search);
    const isSeoPath = !Object.keys(queryParams)?.length;

    const organizationalSchema = JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "CARS24",
        "url": `${appUrl()}/`,
        "logo": "https://media-ae.cars24.com/ae/icon/favicon.ico"
      });

    const schema = JSON.stringify(
        {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": `${appUrl()}/`,
            "potentialAction": {
                "@type": "SearchAction",
                "target": {
                    "@type": "EntryPoint",
                    "urlTemplate": `${appUrl()}/buy-used-cars-${dashedLowercase(DEFAULT_AE_CITY_NAME)}?search={search_term_string}`
                },
                "query-input": "required name=search_term_string"
            }
        }
    );

    return (
        <Helmet script={[{
            type: "application/ld+json",
            innerHTML: schema
        },
        {
            type: "application/ld+json",
            innerHTML: organizationalSchema
        }
        ]}>
            <title>{metaLocale.title}</title>
            <meta name="description" content={metaLocale.description} />
            <meta name="ROBOTS" content={isSeoPath ? "INDEX,FOLLOW" : "NOINDEX,NOFOLLOW"} />
            <link href={`${baseUrl}`} rel="canonical" />
            <link data-react-helmet="true" rel="alternate" href="https://www.cars24.com/" hrefLang="en-IN"/>
            <link data-react-helmet="true" rel="alternate" href="https://www.cars24.com.au/" hrefLang="en-AU"/>
            <link data-react-helmet="true" rel="alternate" href="https://www.cars24.ae/" hrefLang="en-AE"/>
        </Helmet>
    );
};

const mapStateToProps = ({
    config: { ratingInfo = {} } = {}
}) => ({
    ratingInfo
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

MetaTagsCarHome.propTypes = {
    location: PropTypes.object
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MetaTagsCarHome));
