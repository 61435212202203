import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

// Styles
import styles from "./styles.css";

// Images

// components
import ServicingHomeBanner from "../servicing-home-banner";
import ServicingBrands from "../servicing-brands";
import ServicingTypesOfService from "../servicing-types-of-service";
import FaqSection from "../faq-section";
const ServicingHowItWorks = loadable(() => import("../servicing-how-it-works"));
const ServicingChooseCars24 = loadable(() => import("../servicing-choose-cars24"));
const ServicingTestimonials = loadable(() => import("../servicing-testimonials"));
const BlogsSection = loadable(() => import("../blogs-section"));
const ServicingLeadCallback = loadable(() => import("../servicing-lead-callback"));
const LearnMoreAboutCars24 = loadable(() => import("../learn-more-about-cars24"));
const ServicingLeadCapturePopup = loadable(() => import("../servicing-lead-capture-popup"));
const DownloadCars24AppFloatingWidget = loadable(() => import("../download-cars24-app-floating-widget"));

// utils

// constants
import { FAQ_CATEGORIES } from "../../../constants/app-constants";
import { pageSectionsConfig } from "./constants";

const CarServicingHomePage = ({
    faqData = [],
    fetchFaqConnect = () => { },
    fetchPageContentConnect = () => { },
    isDataLoaded,
    pageContent
}) => {

    const PAGE_POPUPS = {
        LEAD_CAPTURE: "LEAD_CAPTURE"
    };
    const serviceContractFaq = (faqData || [])?.find(({ categoryTitle = "" }) => categoryTitle === FAQ_CATEGORIES.SERVICE_CONTRACT)?.faqList || [];

    const [popupToShow, setPopupToShow] = useState("");

    useEffect(() => {
        if (!isDataLoaded || pageContent?.isDefaultConfig) {
            fetchPageContentConnect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDataLoaded]);

    useEffect(() => {
        if (!faqData?.length) {
            fetchFaqConnect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { pageSections = [] } = pageContent;

    return (
        <div>
            {(pageSections || []).map(pageSectionInfo => {
                const { pageSection = {} } = pageSectionInfo;
                const { name, title: sectionTitle } = pageSection;
                switch (name) {
                    case pageSectionsConfig.SERVICING_HOME_PAGE_DESKTOP_HERO_BANNER:
                        return (<section style={{ minHeight: "30vw" }} >
                            <ServicingHomeBanner onCtaClick={() => { setPopupToShow(PAGE_POPUPS.LEAD_CAPTURE);}} sectionInfo={pageSection}  />
                        </section>);
                    case pageSectionsConfig.SERVICING_HOME_PAGE_DESKTOP_BRANDS:
                        return (<section style={{ minHeight: 296 }} >
                            <ServicingBrands />
                        </section>);
                    case pageSectionsConfig.SERVICING_HOME_PAGE_DESKTOP_TYPES_OF_SERVICES:
                        return (<section style={{ minHeight: 647 }} >
                            <ServicingTypesOfService  sectionInfo={pageSection} />
                        </section>);
                    case pageSectionsConfig.SERVICING_HOME_PAGE_DESKTOP_HOW_IT_WORKS:
                        return (<section style={{ minHeight: 338 }} >
                            <ServicingHowItWorks  sectionInfo={pageSection}/>
                        </section>);
                    case pageSectionsConfig.SERVICING_HOME_PAGE_DESKTOP_BENEFITS:
                        return (<section style={{ minHeight: 359 }} >
                            <ServicingChooseCars24 sectionInfo={pageSection}/>
                        </section>);
                    case pageSectionsConfig.SERVICING_HOME_PAGE_DESKTOP_TESTIMONIAL:
                        return (<section style={{ minHeight: 580 }} >
                            <ServicingTestimonials sectionInfo={pageSection} />
                        </section>);
                    case pageSectionsConfig.SERVICING_HOME_PAGE_DESKTOP_BLOG_SECTION:
                        return (<section style={{ minHeight: 422 }} >
                            <BlogsSection sectionInfo={pageSection}/>
                        </section>);
                    case pageSectionsConfig.SERVICING_HOME_PAGE_DESKTOP_REQUEST_CALLBACK_SECTION:
                        return (<section style={{ minHeight: 326 }} >
                            <ServicingLeadCallback />
                        </section>);
                    case pageSectionsConfig.SERVICING_HOME_PAGE_DESKTOP_FAQ:
                        return (<section style={{ minHeight: 500 }} >
                            <FaqSection
                                faqs={serviceContractFaq}
                                title={sectionTitle}
                            />
                        </section>);
                    case pageSectionsConfig.SERVICING_HOME_PAGE_DESKTOP_LEARN_MORE_SECTION:
                        return (<section style={{ minHeight: 360 }} >
                            <LearnMoreAboutCars24 />
                        </section>);
                    default: return null;
                }
            })}
            <div>
                <DownloadCars24AppFloatingWidget />
            </div>
            {
                PAGE_POPUPS.LEAD_CAPTURE === popupToShow &&
                <div>
                    <ServicingLeadCapturePopup onClose={() => { setPopupToShow(""); }} />
                </div>
            }
        </div>
    );
};

CarServicingHomePage.propTypes = {
    faqData: PropTypes.array,
    fetchFaqConnect: PropTypes.func,
    fetchPageContentConnect: PropTypes.func,
    isDataLoaded: PropTypes.bool,
    pageContent: PropTypes.object
};

export default CarServicingHomePage;
