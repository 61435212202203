import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import loadable from "@loadable/component";

// Component
import withLayout from "../../../components/ae.desktop/layout";
import PageLoader from "../../../components/shared/page-loader";
import ErrorHandler from "../../../components/shared/with-error-handler";

const SeoCarDetailsComponent = loadable(() => import("../../../components/ae.desktop/seo-car-details"), { fallback: <PageLoader /> });

const SeoCarDetails = () => {
    return (
        <Fragment>
            <Helmet>
                <meta name="ROBOTS" content="NOINDEX,NOFOLLOW" />
            </Helmet>
            <ErrorHandler>
                <SeoCarDetailsComponent />
            </ErrorHandler>
        </Fragment>
    );
};

export default withLayout(SeoCarDetails, true, true);
