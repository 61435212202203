import React from "react";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import withAELayout from "../../../components/ae.desktop/layout";
import SellerCarHome from "../../../components/ae.desktop/seller-car-home";
import ErrorHandler from "../../../components/shared/with-error-handler";
import { SELLER_HOME_ROUTE } from "../../../constants/ae/sell-car-online";
import { appUrl } from "../../../constants/url-constants";
import homeLocale from "../../../locales/desktop/seller-home";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const SellerHome = () => {
    const baseUrl = `${appUrl()}${SELLER_HOME_ROUTE}`;
    const { title: seoTitle, description, h1 } = homeLocale.ae;

    return (
        <React.Fragment>
             <Helmet>
                <title>{seoTitle}</title>
                <meta name="description" content={description} />
                <meta name="H1" content={h1} />
                <meta name="ROBOTS" content="INDEX,FOLLOW" />
                <link href={`${baseUrl}`} rel="canonical" />
            </Helmet>
            <ErrorHandler>
                <SellerCarHome />
            </ErrorHandler>
        </React.Fragment>
    );
};
const mapStateToProps = ({
    config: { ratingInfo = {} } = {}
}) => ({
    ratingInfo
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

SellerHome.propTypes = {
    location: PropTypes.object
};
export default withAELayout(connect(mapStateToProps, mapDispatchToProps)(SellerHome));
