import { checkDateWithinInterval } from "../../../utils/helpers/check-date-within-interval";
import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

const COUPON_INITIAL_STATE = {
    isCouponListLoading: false,
    isCouponListFetched: false,
    couponList: null
};

export const INITIAL_STATE = {
    cityList: [],
    map: "googlemap",
    platform: "desktop",
    reservationDays: 0,
    reservationHours: 0,
    bookingAmount: 0,
    activeSales: {},
    userVariant: null,
    activeBookingCount: 0,
    bookingAllowed: true,
    maxMrlTdAllowed: 0,
    resolutionInfo: {
        app: {
            carDetails: 500,
            gallery: 1000,
            listing: 300
        },
        desktop: {
            carDetails: 700,
            gallery: 1080,
            listing: 720
        },
        msite: {
            carDetails: 700,
            gallery: 1080,
            listing: 720
        }
    },
    couponData: COUPON_INITIAL_STATE,
    activeSaleConfig: {},
    ratingInfo: {}
};

export const fetchCityListSuccess = (state = INITIAL_STATE, { cityList }) => {
    return { ...state, cityList };
};

export const fetchConfigSuccess = (state = INITIAL_STATE, { configData, cityList, ratingInfo, footerSection, budgetSection }) => {
    let saleDetails = configData?.saleConfig?.saleData;
    if (saleDetails && saleDetails?.active) {
        const {start, end} = saleDetails || {};
        saleDetails = {...saleDetails, ...{ active: checkDateWithinInterval(start, end) }};
    }

    return {
        ...state,
        cityList,
        ...configData,
        budgetSection: state?.budgetSection || budgetSection,
        footerSection: state?.footerSection || footerSection,
        activeSaleConfig: saleDetails,
        ratingInfo
    };
};

export const setBookingCount = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        ...data
    };
};

export const updateSaleData = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        ...data
    };
};

// COUPON LIST
const fetchCouponListInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        couponData: {
            ...state.couponData,
            isCouponListLoading: true,
            isCouponListFetched: false,
            couponList: null
        }
    };
};

const fetchCouponListSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        couponData: {
            ...state.couponData,
            isCouponListLoading: false,
            isCouponListFetched: true,
            couponList: data
        }
    };
};

const fetchCouponListFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        couponData: {
            ...state.couponData,
            isCouponListLoading: false,
            isCouponListFetched: true,
            couponList: null
        }
    };
};

export const HANDLERS = {
    [Types.FETCH_CITY_LIST_SUCCESS]: fetchCityListSuccess,
    [Types.FETCH_CONFIG_SUCCESS]: fetchConfigSuccess,
    [Types.FETCH_BOOKING_COUNT]: setBookingCount,
    [Types.UPDATE_SALE_DATA]: updateSaleData,

    // COUPON LIST
    [Types.FETCH_COUPON_LIST_INIT]: fetchCouponListInit,
    [Types.FETCH_COUPON_LIST_SUCCESS]: fetchCouponListSuccess,
    [Types.FETCH_COUPON_LIST_FAILURE]: fetchCouponListFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
