import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";

//constants
import { NUMBER } from "../../../constants/app-constants";

const OverviewSection = ({ sectionInfo = [], services = [] }) => {

    if (!sectionInfo || sectionInfo.length === 0) {
        return null;
    }

    return (
        <Fragment>
            <div styleName={"styles.overviewSectionTitle"}> OVERVIEW </div>
            <div styleName={"styles.overviewSectionWrapper"}>
                {(sectionInfo || []).map((lineItem, index) => {
                    const { title, action } = lineItem || {};
                    return (
                        <div styleName={`styles.sectionLineItem ${index % NUMBER.TWO !== 0 ? "styles.evenLineItem" : ""}`} key={`overSection_${title}`} >
                            <div styleName={"styles.description"}>
                                {title}
                            </div>
                            <div styleName={"styles.values"}>
                                {(services || []).map(service => {
                                    return (<div styleName={"styles.value"} key={`overSection_${title}_${service}`} style={{fontWeight: 500}}> {action[service]} </div>);
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>

        </Fragment>
    );
};

OverviewSection.propTypes = {
    sectionInfo: PropTypes.array,
    services: PropTypes.array
};

export default OverviewSection;
