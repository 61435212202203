import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Images
import GearImage from "./images/gear-image.png";

// components
import LazyImage from "../../shared/lazy-image";
import ServiceDetailsCard from "./service-details-card";
import SlickWrapper from "../../shared/slick-wrapper";
import isEmpty from "../../../utils/helpers/is-empty";
// import Button from "../../shared/button";

// constants

// tracking

const ServicingTypesOfService = ({ typesOfServiceSection = {}, tofServices = {}, fetchServiceDescriptionConnect, sectionInfo = {} }) => {

    useEffect(() => {
        if (isEmpty(tofServices)) {
            fetchServiceDescriptionConnect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sliderSettings = {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrow: true,
        dots: false,
        infinite: false,
        gap: 2
    };

    const {
        title = "",
        description = ""
    } = typesOfServiceSection || {};

    const {
        serviceDescriptions = []
    } = tofServices || {};

    const {
        title: sectionTitle,
        subTitle
    } = sectionInfo || {};

    return (

        <React.Fragment>
            <div styleName={"styles.typesOfServiceTitleCtn"} className="container"  >
                {sectionTitle && <p styleName={"styles.mainTitle"} >{sectionTitle}</p>}
                {subTitle && <p styleName={"styles.subTitle"} >{subTitle}</p>}
            </div>
            <div styleName={"styles.gradientBg"} >
                <div styleName={"styles.typesOfServiceSectionWrapper"} className="container"   >
                    <div>
                        <div styleName={"styles.typesOfServiceBodyCtn"}  >
                            <div styleName={"styles.serviceDescription"} >
                                <div styleName={"styles.shieldIcon"} >
                                    <LazyImage src={GearImage} />
                                </div>
                                <p styleName={"styles.mainText"} >{title}</p>
                                <p styleName={"styles.subText"} >{description}</p>
                                {/* <div styleName={"styles.learnMoreCta"}  >
                                    <Button text={"LEARN MORE"} />
                                </div> */}
                            </div>
                            {
                                serviceDescriptions?.length > 0 && <div styleName={"styles.serviceCardsSlider"}  >
                                    <SlickWrapper {...sliderSettings}>
                                        {
                                            (serviceDescriptions || []).map(tofService => {
                                                return (<ServiceDetailsCard serviceInfo={tofService} key={tofService?.serviceId} />);
                                            })
                                        }
                                    </SlickWrapper>

                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

ServicingTypesOfService.propTypes = {
    typesOfServiceSection: PropTypes.object,
    tofServices: PropTypes.object,
    fetchServiceDescriptionConnect: PropTypes.func,
    sectionInfo: PropTypes.object
};

export default ServicingTypesOfService;
