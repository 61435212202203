import React from "react";
import withAELayout from "../../../components/ae.desktop/layout";
import styles from "./styles.css";
import H1 from "../../../components/shared/h1/component";
import ContactCard from "../../../components/shared/contact-us-card";
import { MAIL_CARE_HELP_CENTER, CALL_CENTER_NUMBER } from "../../../constants/app-constants";
import { Helmet } from "react-helmet-async";

// Constants
import { appUrl } from "../../../constants/url-constants";

const ContactUs = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>CARS24 UAE | Contact Us</title>
                <meta name="description" content={"CARS24 is a fast growing auto-tech company in UAE to buy and sell used cars. Check out our verified cars at your nearest CARS24 branch."} />
                <meta name="ROBOTS" content="INDEX,FOLLOW" />
                <link href={`${appUrl()}/contact/`} rel="canonical" />
            </Helmet>
            <div styleName={"styles.outer"}>
                <div className={"container"}>
                    <div className={"row"} styleName={"spacing"}>
                        <div className={"col-3"} styleName={"styles.header"}>
                            <H1 text="LOOKING TO GET IN TOUCH WITH CARS24 TEAM?" />
                        </div>
                        <div className={"col-5"} styleName={"height"}>
                            <ContactCard heading="Contact Us" imageType="number">
                                <p styleName={"styles.emailDetail"}><span>Call Us:</span>
                                    <a href={`tel:${CALL_CENTER_NUMBER.value}`}>{CALL_CENTER_NUMBER.label}</a>
                                </p>
                                <p styleName={"styles.emailDetail"}><span>Email Us:</span>
                                    <a href={`mailto:${MAIL_CARE_HELP_CENTER}`}> {MAIL_CARE_HELP_CENTER}</a>
                                </p>
                            </ContactCard>
                            <ContactCard heading="Email" imageType="mail">
                                <p styleName={"styles.emailDetail"}><span>Careers:</span>
                                    <a href="mailto:uae.careers@cars24.com">uae.careers@cars24.com</a>
                                </p>

                                <p styleName={"styles.emailDetail"}>
                                    <span>Alliances/Partnership:</span>
                                    <a href="mailto:alliances@cars24.com">alliances@cars24.com</a>
                                </p>

                                <p styleName={"styles.emailDetail"}><span>Media queries:</span>
                                    <a href="mailto:press@cars24.com">press@cars24.com</a>
                                </p>

                                <p styleName={"styles.emailDetail"}><span>Whistle Blower:</span>
                                    <a href="mailto:whistle@cars24.com">whistle@cars24.com</a>
                                </p>

                            </ContactCard>
                        </div>
                        <div className={"col-4"}>
                            <ContactCard heading="Corporate Office Address" imageType="office">
                                <p styleName={"styles.address"}>
                                    Auto New Market Block - 008,<br />
                                    Ras Al Khor Industrial Area 3,<br />
                                    Al Aweer, Dubai, UAE
                                </p>
                            </ContactCard>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
};

export default withAELayout(ContactUs);
