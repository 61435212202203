import React, { useState } from "react";
import PropTypes from "prop-types";

//components
import Modal from "../../shared/modal";
import CloseButton from "../../shared/icon-cross";
import OverViewSection from "./overview-section";

//images

//styles
import styles from "./styles.css";
import ExpandableSection from "./expandable-section";

const servicesConfig = {
    "minor": "minor",
    "major": "major",
    "doorstep": "doorstep"
};

const serviceBgColors = {
    [servicesConfig.minor]: "#ECF9FF",
    [servicesConfig.major]: "#FFF4EA",
    [servicesConfig.doorstep]: "#FFEEFE"
};

const serviceTitles = {
    [servicesConfig.minor]: "MINOR SERVICE",
    [servicesConfig.major]: "MAJOR SERVICE",
    [servicesConfig.doorstep]: "DOORSTEP"
};

const ViewMoreServicesPopup = ({ onClose, tofServices }) => {

    const {
        serviceOverview,
        services = [],
        categories = []
    } = tofServices || {};

    const [activeStep, setActiveStep] = useState(categories[0]?.name);

    const onAccordionChange = (serviceName) => {
        if (serviceName === activeStep) {
            setActiveStep("");
        } else {
            setActiveStep(serviceName);
        }
    };

    return (
        <Modal isOpen={true} close={onClose}>
            <div styleName={"styles.viewMoreSectionPopupWrapper"}>
                <div styleName={"styles.headerWrapper"}>
                    <div styleName={"styles.header"}>
                        <div styleName={"styles.headerText"}>Types of services</div>
                        <CloseButton type="orange" onClickHandler={onClose} width={"24px"} height={"24px"} />
                    </div>
                    <div styleName={"styles.serviceHeaderWrapper"}>
                        {(services || []).map(service => {
                            return (<div styleName={"styles.serviceHeader"} key={`serviceTitle_${service}`} style={{ background: serviceBgColors[service] }}>{serviceTitles[service]}</div>);
                        })}
                    </div>
                </div>
                <div styleName={"styles.sectionsWrapper"}>
                    <OverViewSection sectionInfo={serviceOverview} services={services} />
                    {(categories || []).map((category => {
                        return (<ExpandableSection key={category?.name} sectionInfo={category} services={services} isExpanded={category?.name === activeStep} onAccordionChange={onAccordionChange} />);
                    }))}
                </div>
            </div>
        </Modal>
    );
};

ViewMoreServicesPopup.propTypes = {
    onClose: PropTypes.func,
    tofServices: PropTypes.object
};

export default ViewMoreServicesPopup;

