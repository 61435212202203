import baseApi from "./baseApi";
import paymentApi from "./payment-service";
import usedCarApi from "./used-car-service";
import c2cBaseApi from "./c2cbaseApi";
import cmsBaseApi from "./cms-base-api";
import checkoutAEApi from "./checkout-service-ae";
import checkoutAEBaseApi from "./checkout-ae-base-api";
import carApi from "./car-service";
import listingBaseApi from "./listing-api";
import listingApi from "./listing-service";
import secureListingApi from "./secure-listing-service";

import userAuth from "./userAuthApi";
import userAuthApi from "./user-auth-service";

import userProfile from "./userProfileApi";
import userProfileApi from "./user-profile-service";

import userConsentBaseApi from "./userConsentApi";
import userConsentApi from "./user-consent-service";

import b2cInventoryBaseApi from "./b2cInventoryApi";

import b2cInventoryApi from "./b2c-inventory-service";

import thirdPartyBaseApi from "./thirdparty-api";
import thirdParyApi from "./thirdparty-service";

import b2cMasterDataBaseApi from "./b2cMasterDataApi";
import b2cMasterDataApi from "./b2c-master-data-service";

import b2cMasterDataMeBaseApi from "./b2cMasterDataMeApi";
import b2cMasterDataMeApi from "./b2c-master-data-me-service";

import cloudBaseApi from "./cloud-base-api";
import cloudServiceApi from "./cloud-service";

import cfApi from "./cf-service";
import cfBaseApi from "./cf-base-api";

import deliveryApi from "./delivery-service";
import deliveryBaseApi from "./delivery-base-api";
import c2bAppointmentApi from "./c2b-appointment-service";
import c2bAppointmentBaseApi from "./c2b-appointment-base-api";

import uaePaymentApi from "./b2c-uae-payment-service";
import uaePaymentBaseApi from "./b2cUaePaymentApi";
import uaeCcBaseApi from "./b2cUaeCcApi";
import b2cUaeCcApi from "./b2c-uae-cc-service";

import crmApi from "./crm-service";
import crmBaseApi from "./crmBaseApi";
import sellerVariantApi from "./seller-variant-service";
import uaeApiGatewayService from "./uae-api-gateway-service";
import sellerVariantBaseApi from "./seller-variant-base-api";
import c2bOmsService from "./c2b-oms-service";
import c2bOmsBaseAPI from "./c2b-oms-base-api";

import c2cInventoryService from "./c2c-inventory-service";
import c2cInventoryApi from "./c2c-inventory-base-api";

import slotPlannerApi from "./slot-planner-service";
import slotPlannerBaseApi from "./slot-planner-base-api";
import sellerSlotsApi from "./seller-slots-service";
import sellerSlotsBaseApi from "./seller-slots-base-api";

import couponApi from "./coupon-service";
import couponBaseApi from "./coupon-base-api";
import b2cVasBaseApi from "./b2c-vas-api";
import b2cVasApi from "./b2c-vas-service";
import cmsApi from "./cms-service";

const userProfileAuth = userProfileApi(userProfile);
const userAuthenticate = userAuthApi(userAuth);
const payment = paymentApi(baseApi);
const uaePayment = uaePaymentApi(uaePaymentBaseApi);
const uaeCcService = b2cUaeCcApi(uaeCcBaseApi);
const usedCar = usedCarApi(c2cBaseApi);
const userConsent = userConsentApi(userConsentBaseApi);
const car = carApi(baseApi);
const checkoutAE = checkoutAEApi(checkoutAEBaseApi);
const listing = listingApi(listingBaseApi);
const b2cInventory = b2cInventoryApi(b2cInventoryBaseApi);

const secureListing = secureListingApi(listingBaseApi);
const thirdparty = thirdParyApi(thirdPartyBaseApi);
const b2cMasterData = b2cMasterDataApi(b2cMasterDataBaseApi);
const b2cMasterDataMe = b2cMasterDataMeApi(b2cMasterDataMeBaseApi);

const cloudFunction = cloudServiceApi(cloudBaseApi);

const consumerFinance = cfApi(cfBaseApi);
const delivery = deliveryApi(deliveryBaseApi);

const c2bAppointment = c2bAppointmentApi(c2bAppointmentBaseApi);
const sellerVariant = sellerVariantApi(sellerVariantBaseApi);
const uaeApiGateway = uaeApiGatewayService(sellerVariantBaseApi);
const c2bOms = c2bOmsService(c2bOmsBaseAPI);
const c2cInventory = c2cInventoryService(c2cInventoryApi);

const crm = crmApi(crmBaseApi);

const slotPlanner = slotPlannerApi(slotPlannerBaseApi);
const sellerSlots = sellerSlotsApi(sellerSlotsBaseApi);

const couponService = couponApi(couponBaseApi);
const b2cVas = b2cVasApi(b2cVasBaseApi);

const cmsService = cmsApi(cmsBaseApi);

export {
    baseApi,
    car as CarService,
    payment as PaymentService,
    usedCar as UsedCarService,
    listing as ListingService,
    checkoutAE as CheckoutAEService,
    userAuthenticate as UserAuthService,
    b2cInventory as B2cInventoryService,
    secureListing as SecureListingService,
    thirdparty as ThirdPartyService,
    b2cMasterData as B2CMasterData,
    cloudFunction as CloudService,
    consumerFinance as CFService,
    userConsent as UserConsentService,
    delivery as DeliveryService,
    uaePayment as UaePaymentService,
    c2bAppointment as C2bAppointmentService,
    crm as CrmService,
    sellerVariant as SellerVariantService,
    slotPlanner as SlotPlannerService,
    sellerSlots as SellerSlotsService,
    c2bOms as C2BOmsService,
    b2cMasterDataMe as B2CMasterDataMe,
    userProfileAuth as userProfileService,
    couponService,
    uaeApiGateway as UaeApiGatewayService,
    b2cVas as b2cVasService,
    c2cInventory as C2CInventoryService,
    cmsService,
    uaeCcService
};
