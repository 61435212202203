import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AEConfiguration from "./component";
import { getRefreshTokenInfo, setProfileWithToken, logoutSuccess, setGAId, userProfile } from "./actions";
import { reloadCarList } from "../ae.mobile/car-listing/actions";
import { getConfig, updateSaleDataConfig as updateSaleDataConfigMsite } from "../ae.mobile/config-ssr/actions";
import { updateSaleDataConfig as updateSaleDataConfigDesktop } from "../ae.mobile/config-ssr/actions";
import { setExperiment, setStatsigInitialization } from "../shared/ab-experiment/actions";
import { withRouter } from "react-router-dom";
import { updateCityWithCode as updateCityWithCodeDesktop } from "../ae.desktop/header-revamp/actions";
import { updateCityWithCode } from "../ae.mobile/location-picker/actions";

const mapStateToProps = ({
    config: { map: mapType, cityList, platform, gaId, activeSaleConfig, ratingInfo = {} } = {},
    carListing: {
        isSSR: isListingSSR
    },
    user: {
        mobile,
        isLoggedIn,
        isFetched: isUserDetailsFetched,
        name,
        email,
        ip,
        secureToken
    },
    experiments = {},
    cities: { selectedCity = {} } = {},
    location: { selectedCity: selectedCityInfo = {} } = {}
}) => ({
    mapType,
    cityList,
    isListingSSR,
    platform,
    mobile,
    isLoggedIn,
    isUserDetailsFetched,
    name,
    email,
    gaId,
    experiments,
    selectedCity,
    selectedCityInfo,
    ip,
    activeSaleConfig,
    secureToken,
    ratingInfo
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getRefreshTokenInfoConnect: getRefreshTokenInfo,
    reloadCarListConnect: reloadCarList,
    setProfileWithTokenConnect: setProfileWithToken,
    logoutSuccessConnect: logoutSuccess,
    setExperimentConnect: setExperiment,
    setGAIdConnect: setGAId,
    getConfigConnect: getConfig,
    updateCityWithCodeMobileConnect: updateCityWithCode,
    updateCityWithCodeDesktopConnect: updateCityWithCodeDesktop,
    setStatsigInitializationConnect: setStatsigInitialization,
    updateSaleDataMsiteConnect: updateSaleDataConfigMsite,
    updateSaleDataDesktopConnect: updateSaleDataConfigDesktop,
    userProfileConnect: userProfile
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AEConfiguration));
