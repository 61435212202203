import React from "react";
import PropTypes from "prop-types";
import { Redirect, withRouter } from "react-router-dom";
import { renderRoutes } from "react-router-config";

// Component
import AEConfiguration from "./components/ae.configuration";
import Home from "./pages/ae.desktop/home";
import CarDetail from "./pages/ae.desktop/car-detail";
import CarListing from "./pages/ae.desktop/car-listing";
import Checkout from "./pages/ae.desktop/checkout";
import AboutUs from "./pages/ae.desktop/about-us";
import ContactUs from "./pages/ae.desktop/contact-us";
import PrivacyPolicy from "./pages/ae.desktop/privacy-policy";
import TermsOfUse from "./pages/ae.desktop/terms-of-use";
import Faq from "./pages/ae.desktop/faq";
import MyBookings from "./pages/ae.desktop/my-bookings";
import MyWishlist from "./pages/ae.desktop/my-wishlist";
import NotFound from "./pages/ae.desktop/not-found";
import SiteMap from "./pages/ae.desktop/sitemap";
import OpenInMobile from "./pages/ae.desktop/open-in-mobile";
import Profile from "./pages/ae.desktop/profile";
import SellerHome from "./pages/ae.desktop/seller-home";
import PostBookingSteps from "./pages/ae.desktop/post-bc-step-mapper";
import SellCarDetails from "./pages/ae.desktop/sell-car-details";
import SeoCarDetails from "./pages/ae.desktop/seo-car-details";
import CarLoan from "./pages/ae.desktop/car-loan";
import C2COpenInMobilePage from "./pages/ae.desktop/c2c-open-in-mobile";
import CarServicingHome from "./pages/ae.desktop/car-servicing-home";

// Constants
import { ROUTE_PATHS, POSTBC_ROUTES, ROUTE_NAME } from "./constants/routes-constants";
import { SELLER_HOME_ROUTE } from "./constants/ae/sell-car-online";

const Root = ({ route, children }) => {
    return (
        <AEConfiguration>
            {renderRoutes(route.routes)}
            {children}
        </AEConfiguration>
    );
};

Root.propTypes = {
    route: PropTypes.object,
    children: PropTypes.object
};

const routes = [
    {
        path: "/",
        component: withRouter(Root),
        init: "./init-top.ae.desktop",
        routes: [
            {
                path: "/",
                exact: true,
                isUsedCarRoute: true,
                name: "ae.home",
                component: Home,
                init: "./ae/home.desktop"
            },
            {
                path: "/buy-used-:carMakeModel-cars-:city-:lid(\\d+)",
                exact: true,
                name: "ae.car-details",
                component: CarDetail,
                init: "./ae/details.desktop"
            },
            {
                path: "/buy-used-:filterQuery",
                exact: true,
                name: "ae.listing",
                component: CarListing,
                init: "./ae/listing.desktop"
            },
            {
                path: "/buy-used-:carMakeModel-cars-:city-:lid(\\d+)/checkout",
                exact: true,
                component: Checkout,
                name: "ae.car-details-checkout",
                init: "./ae/details.desktop"
            },
            {
                path: "/new-car/:make/:model/:pageType?",
                exact: true,
                component: SeoCarDetails,
                isUsedCarRoute: true,
                name: ROUTE_NAME.SEO_CAR_DETAILS,
                init: "./ae/seo-details.desktop"
            },
            {
                path: "/about-us",
                exact: true,
                name: "about-us",
                component: AboutUs
            },
            {
                path: "/contact",
                exact: true,
                name: "contact-us",
                component: ContactUs
            },
            {
                path: "/privacy-policy",
                exact: true,
                name: "privacy-policy",
                component: PrivacyPolicy
            },
            {
                path: "/terms-of-use",
                exact: true,
                name: "terms-of-use",
                component: TermsOfUse
            },
            {
                path: "/faq",
                exact: true,
                name: "faq",
                component: Faq,
                init: "./ae/faq.desktop"
            },
            {
                path: "/my-bookings",
                exact: true,
                component: MyBookings,
                name: "ae.my-bookings"
            },
            {
                path: "/my-wishlist",
                exact: true,
                component: MyWishlist,
                name: "ae.my-wishlist"
            },
            {
                path: "/sitemap",
                exact: true,
                name: "sitemap",
                component: SiteMap,
                init: "./ae/sitemap.desktop"
            },
            {
                path: `/${ROUTE_PATHS.tofService}/:stepid`,
                exact: true,
                isUsedCarRoute: true,
                component: OpenInMobile,
                name: `ae-${ROUTE_PATHS.tofService}`
            },
            {
                path: `/${ROUTE_PATHS.carServicing}`,
                exact: true,
                isUsedCarRoute: true,
                component: CarServicingHome,
                name: `ae.${ROUTE_PATHS.tofServiceHome}`,
                init: "./ae/car-servicing.desktop"
            },
            {
                path: `/${ROUTE_PATHS.carLoan}`,
                exact: true,
                isUsedCarRoute: true,
                component: CarLoan,
                name: `ae.${ROUTE_PATHS.carLoan}`,
                init: "./ae/car-loan.desktop"
            },
            {
                path: `/profile`,
                exact: true,
                name: "ae.profile",
                component: Profile
            },
            {
                path: `/${ROUTE_PATHS.loanPreApproval}`,
                exact: true,
                component: OpenInMobile,
                name: `ae-${ROUTE_PATHS.loanPreApproval}`
            },
            {
                path: `/${ROUTE_PATHS.postBooking}/:lid/:stepid`,
                exact: true,
                component: PostBookingSteps,
                name: `ae.${ROUTE_PATHS.postBooking}`
            },
            // M-Site Routes - START
            // Post Booking Routes
            {
                path: `/${ROUTE_PATHS.payment}/:appId`,
                exact: true,
                component: OpenInMobile,
                name: `ae-${ROUTE_PATHS.payment}`
            },
            {
                path: `/${ROUTE_PATHS.payment}/:orderId/transaction`,
                exact: true,
                component: OpenInMobile,
                name: `ae-${ROUTE_PATHS.payment}-transaction`
            },
            {
                path: `/${ROUTE_PATHS.postBooking}/:lid/${POSTBC_ROUTES.testDrive}/schedule/:stepid`,
                exact: true,
                component: OpenInMobile,
                name: `ae-${ROUTE_PATHS.postBooking}-tdschedule`
            },
            {
                path: `/${ROUTE_PATHS.feedback}/:orderId/:feedbackType`,
                exact: true,
                component: OpenInMobile,
                name: `ae-${ROUTE_PATHS.feedback}`
            },
            // Help Center Route
            {
                path: "/help-center",
                exact: true,
                name: "helpCenter",
                component: OpenInMobile
            },
            // Seller Routes
            {
                path: SELLER_HOME_ROUTE,
                exact: true,
                name: "ae.sell-online",
                isUsedCarRoute: false,
                init: "./ae/seller-home.desktop",
                component: SellerHome
            },
            {
                /* Old homepage route for seller used in marketing
                so have to redirect to new url (10% chances) */
                path: "/sell-online",
                exact: true,
                isUsedCarRoute: false,
                name: "ae.sell-online-old",
                // eslint-disable-next-line react/prop-types
                component: (props) => <Redirect to={`${SELLER_HOME_ROUTE}${props?.location?.search}`} />
            },
            {
                path: `/${ROUTE_PATHS.carEvaluation}/:stepid`,
                exact: true,
                name: "coming-soon",
                component: OpenInMobile
            },
            {
                path: "/book-appointment/:stepid",
                exact: true,
                name: "coming-soon",
                component: OpenInMobile
            },
            {
                path: "/sell-used-car-:city",
                exact: true,
                isUsedCarRoute: false,
                name: "ae.sell-online",
                init: "./ae/seller-home.desktop",
                component: SellCarDetails
            },
            {
                path: "/seller/revaluation/:appointmentId",
                exact: true,
                name: "coming-soon",
                component: OpenInMobile
            },
            {
                path: "/seller/upload-documents/:appointmentId",
                exact: true,
                name: "coming-soon",
                component: OpenInMobile
            },
            {
                path: "/seller/marketplace",
                exact: false,
                name: "ae.market-place",
                isUsedCarRoute: false,
                component: C2COpenInMobilePage
            },
            {
                path: `/seller-center/:stepId?`,
                exact: false,
                name: "ae.seller-center",
                isUsedCarRoute: false,
                component: C2COpenInMobilePage
            },
            // M-Site Routes - END
            // KEEP SLUG AT THE LAST OF THIS FILE
            {
                path: "/:slug1?/:slug2?/:slug3?/:slug4?",
                exact: true,
                name: "ae.home",
                component: NotFound,
                init: "./ae/not-found"
            },
            {
                path: "*",
                exact: true,
                name: "ae.not-found",
                component: NotFound
                // component: () => <div>Mobile - 404</div>
                // init: "./home.mobile"
            }
        ]
    }
];

export { routes };
