import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";

//Images/
import IconPrimeTag from "./images/prime-tag.svg";
import IconGreenTag from "./images/green-tag.svg";
import IconLiteTag from "./images/lite-tag.svg";
import IconPrivateSeller from "./images/private-seller-tag.svg";
import IconPrivateSellerPro from "./images/private-seller-tags-pro.svg";

import BrandNewIcon from "../../shared/images/car-card-tags/brand-new.svg";
import ComingSoonIcon from "../../shared/images/car-card-tags/coming-soon.svg";
import InDemandIcon from "../../shared/images/car-card-tags/in-demand.svg";
import FeaturedIcon from "../../shared/images/car-card-tags/featured.svg";
import HotOfferIcon from "../../shared/images/car-card-tags/hot-offer.svg";

// Utils
import { fastlyUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";
import getDetailPageUrl from "../../../utils/helpers/get-detail-page-url";
import { checkCarStatus, getCarName, getEmiLabel, imageAltTagLabel, getCarPriceLabelWithoutCurrency } from "../../../utils/helpers/car-properties.ae";

//Constants
import { CAR_STATUS, FASTLY_BASE_URL, LOGIN_TITLE, NUMBER, ASSORTMENT_CATEGORY_LABEL, carCardTags } from "../../../constants/app-constants";

import IconHeart from "../../shared/icon-heart/component";
import withWishlist from "../with-wishlist";
import LazyImage from "../../shared/lazy-image";

// Tracking
import cleverTap from "../../../tracking/clevertap";
import Login from "../login";
import { SOURCES } from "../login/constants";
import { trackMobileCustomEventsAE } from "../../../tracking";
import { homeCarSections } from "../home-car-section/constansts";

const SmallerCarCard = ({
    carInfo,
    index,
    preloadNumber = NUMBER.ZERO,
    isWishlistedCar,
    secureToken,
    onClickAddToWishList,
    isLoggedIn,
    handleCarClick,
    sectionName
}) => {
    const {
        appointmentId,
        year,
        assortmentCategory,
        make,
        model,
        city,
        featured,
        mainImage,
        price,
        listingActive,
        isHotOffer,
        hotOfferDetails,
        carCardTag
    } = carInfo || {};
    const carName = `${make} ${model}`;
    const [showLogin, setShowLogin] = useState(false);
    const { relativeURL, absoluteURL } = getDetailPageUrl({
        year,
        carName,
        carId: appointmentId,
        city,
        country: "ae"
    });
    const carImgSrc = fastlyUrl({
        basePath: `${FASTLY_BASE_URL}/`,
        path: mainImage?.path,
        type: imageTypes.aeCarDetailPreview,
        width: 200,
        queryParams: mainImage?.params
    });
    const isCarSold = checkCarStatus({ listingActive }, CAR_STATUS.SOLD);
    const carCategory = {
        [ASSORTMENT_CATEGORY_LABEL.PRIME]: { icon: IconPrimeTag },
        [ASSORTMENT_CATEGORY_LABEL.LITE]: { icon: IconLiteTag },
        [ASSORTMENT_CATEGORY_LABEL.GREEN]: { icon: IconGreenTag },
        [ASSORTMENT_CATEGORY_LABEL.PRIVATE_SELLER]: { icon: IconPrivateSeller },
        [ASSORTMENT_CATEGORY_LABEL.PRIVATE_SELLER_PRO]: { icon: IconPrivateSellerPro }
    };
    const preventPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleOnClick = ({
        e,
        relativeURL: carUrl,
        carName: carFullName,
        index: idx,
        appointmentId: carId
    }) => {
        preventPropagation(e);
        handleCarClick({ relativeURL: `${carUrl}?from=homePage`, carName: carFullName, index: idx, carId });
    };

    const hideLogin = () => {
        setShowLogin(false);
    };

    const trackGaEvents = async () => {
        if (isWishlistedCar(appointmentId)) {
            trackMobileCustomEventsAE("removeFromWishlist", {
                eventLabel: "Remove from wishlist - home page"
            });
        } else {
            trackMobileCustomEventsAE("addToWishlistDetailPage", {
                eventLabel: "add_to_wishlist/home page"
            });
        }
    };
    const onWishlistLoginSuccess = ({ token }) => {
        hideLogin();
        onClickAddToWishList(appointmentId, token, {
            callback: (shortListed) => {
                if (shortListed) {
                    cleverTap.updateProfileCarDetails(
                        cleverTap.profileUpdateTypes.WISHLISTED_CAR,
                        {
                            content: {
                                appointmentId,
                                make,
                                model,
                                year,
                                mainImage,
                                price,
                                hotOfferDetails,
                                isHotOffer
                            },
                            config: { imageHost: FASTLY_BASE_URL }
                        }
                    );
                }
                trackGaEvents();
            }
        });
    };
    const onClickWishlist = (e) => {
        preventPropagation(e);
        if (isCarSold) {
            return;
        }
        if (isLoggedIn) {
            onClickAddToWishList(appointmentId, secureToken, {
                callback: (shortListed) => {
                    if (shortListed) {
                        cleverTap.updateProfileCarDetails(
                            cleverTap.profileUpdateTypes.WISHLISTED_CAR,
                            {
                                content: {
                                    appointmentId,
                                    make,
                                    model,
                                    year,
                                    mainImage,
                                    price,
                                    hotOfferDetails,
                                    isHotOffer
                                },
                                FASTLY_BASE_URL
                            }
                        );
                    }
                    trackGaEvents();
                }
            });
        } else {
            setShowLogin(true);
        }
        if (sectionName === homeCarSections.RECENTLY_VIEWED_CARS) {
            trackMobileCustomEventsAE("recentlyViewedCarWishlistClick", {
                eventLabel: appointmentId
            });
        } else if (sectionName === homeCarSections.RECOMMENDED_CARS) {
            trackMobileCustomEventsAE("recommendedCarWishlistClick", {
                eventLabel: appointmentId
            });
        }
    };

    const carCardMapping = {
        [carCardTags["IN DEMAND"]]: {
            style: "styles.inDemand",
            imageIcon: InDemandIcon
        },
        [carCardTags.FEATURED]: {
            style: "styles.featured",
            imageIcon: FeaturedIcon
        },
        [carCardTags["HOT OFFER"]]: {
            style: "styles.hotOffer",
            imageIcon: HotOfferIcon
        },
        [carCardTags.RESERVED]: {
            style: "styles.reserved",
            imageIcon: null
        },
        [carCardTags["BRAND NEW"]]: {
            style: "styles.brandNew",
            imageIcon: BrandNewIcon
        },
        [carCardTags["COMING SOON"]]: {
            style: "styles.comingSoon",
            imageIcon: ComingSoonIcon
        },
        [carCardTags.SOLD]: {
            style: "styles.soldTag",
            imageIcon: null
        }
    };

    return (
        <React.Fragment>
            <a
                href={absoluteURL}
                onClick={(e) =>
                    handleOnClick({ e, relativeURL, carName, index, appointmentId })
                }
                key={index}
            >
                <div styleName={"styles.carCardWrap"} key={index}>
                    <span styleName={"styles.carImage"}>
                        {index < preloadNumber && preloadNumber ? (
                            <img src={carImgSrc} alt={imageAltTagLabel(carInfo)} />
                        ) : (
                            <LazyImage src={carImgSrc} alt={imageAltTagLabel(carInfo)} />
                        )}
                        {featured && <div styleName={"styles.featuredTad"}>FEATURED</div>}
                        {carCategory?.[assortmentCategory] && (
                            <div styleName={"styles.primeTag"}>
                                <img width="56" height="16" alt="Tag" src={carCategory?.[assortmentCategory]?.icon} />
                            </div>
                        )}
                        {carCardTag && <div styleName={`styles.carCardTagChip ${carCardMapping[carCardTag]?.style}`}>
                            {carCardMapping[carCardTag]?.imageIcon && <img src={carCardMapping[carCardTag]?.imageIcon} width={"8"} height={"8"} />} <span>{carCardTag}</span>
                        </div>}
                        <div styleName={"styles.heartWrap"}>
                            <IconHeart
                                active={isWishlistedCar(appointmentId)}
                                onClick={onClickWishlist}
                                source="smallerCarCard"
                            />
                        </div>

                    </span>
                    <div styleName={"styles.carDetail"}>
                        <p styleName={"styles.carName"}>{getCarName(carInfo)}</p>
                        <div styleName={"styles.flexRow"}>
                            <p styleName={"styles.carPrice"}><small>AED</small>{getCarPriceLabelWithoutCurrency(carInfo)}</p>
                            <p styleName={"styles.carEmiPrice"}>| {getEmiLabel(carInfo, "")}<small>/mo</small></p>
                        </div>
                    </div>
                </div>
            </a>
            <Login
                isVisible={showLogin}
                onClose={hideLogin}
                onSuccess={onWishlistLoginSuccess}
                loginSource={LOGIN_TITLE.ADD_TO_WISHLIST}
                loginFor={SOURCES.showLogin}
            />
        </React.Fragment>
    );
};

SmallerCarCard.propTypes = {
    carInfo: PropTypes.object,
    handleOnClick: PropTypes.func,
    index: PropTypes.number,
    preloadNumber: PropTypes.number,
    isWishlistedCar: PropTypes.func,
    setShowLogin: PropTypes.func,
    secureToken: PropTypes.string,
    onClickAddToWishList: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    showLogin: PropTypes.func,
    handleCarClick: PropTypes.func,
    sectionName: PropTypes.string

};
export default withWishlist(SmallerCarCard);
