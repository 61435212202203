export const pageSectionsConfig = {
    "SERVICING_HOME_PAGE_DESKTOP_HERO_BANNER": "servicingHomePageDesktopHeroBanner",
    "SERVICING_HOME_PAGE_DESKTOP_BRANDS": "servicingHomePageDesktopBrands",
    "SERVICING_HOME_PAGE_DESKTOP_TYPES_OF_SERVICES": "servicingHomePageDesktopTypesOfServices",
    "SERVICING_HOME_PAGE_DESKTOP_HOW_IT_WORKS": "servicingHomePageDesktopHowItWorksSection",
    "SERVICING_HOME_PAGE_DESKTOP_BENEFITS": "servicingHomePageDesktopBenefits",
    "SERVICING_HOME_PAGE_DESKTOP_TESTIMONIAL": "servicingHomePageDesktopTestimonial",
    "SERVICING_HOME_PAGE_DESKTOP_BLOG_SECTION": "servicingHomePageDesktopBlogSection",
    "SERVICING_HOME_PAGE_DESKTOP_REQUEST_CALLBACK_SECTION": "servicingHomePageDesktopRequestCallbackSection",
    "SERVICING_HOME_PAGE_DESKTOP_FAQ": "servicingHomePageDesktopFaq",
    "SERVICING_HOME_PAGE_DESKTOP_LEARN_MORE_SECTION": "servicingHomePageDesktopLearnMoreSection"
};

export const defaultPageContent = {
    "isDefaultConfig": true,
    "name": "Servicing Home Page (Desktop)",
    "slug": "car-servicing-desktop",
    "seo": "",
    "breadcrumb": [],
    "pageSections": [
        {
            "displayOrder": 1,
            "pageSection": {
                "id": 1871,
                "name": "servicingHomePageDesktopHeroBanner",
                "title": "Car service at your convenience",
                "subTitle": "Get expert care, pick & drop facility, anywhere",
                "sectionType": "HeroBanner",
                "subSections": [
                    {
                        "id": 1902,
                        "name": "servicingHomePageDesktopHeroBannerDescription",
                        "title": "Description",
                        "sectionType": "WithBoxGrid",
                        "sectionData": [
                            {
                                "image": {
                                    "id": 1320,
                                    "url": "https://cdn.cars24.com/qa/cms/2024/12/30/7b4c499b-fe3f-4855-89f6-b134c354ae47Group%201171280322.png",
                                    "caption": null,
                                    "alternativeText": "no_hidden_charge"
                                },
                                "title": "No hidden charges"
                            },
                            {
                                "image": {
                                    "id": 1319,
                                    "url": "https://cdn.cars24.com/qa/cms/2024/12/30/d25b49ca-0987-4959-ae86-6cbc4d513facFrame%201171280324.png",
                                    "caption": null,
                                    "alternativeText": "genuine_product"
                                },
                                "title": "Genuine spare parts"
                            },
                            {
                                "image": {
                                    "id": 1327,
                                    "url": "https://cdn.cars24.com/qa/cms/2024/12/30/11e76f4e-a0ca-444b-b94e-b9878f8f451aFrame.png",
                                    "caption": null,
                                    "alternativeText": "same_day_service"
                                },
                                "title": "Same day service"
                            }
                        ],
                        "subsectionOrder": null,
                        "buNames": [],
                        "statsig": false
                    }
                ],
                "subsectionOrder": null,
                "ctaType": "POPUP",
                "ctaText": "BOOK YOUR CAR SERVICE NOW!",
                "backgroundImage": {
                    "caption": null,
                    "alternativeText": "Image_Servicing_Hero",
                    "url": "https://cdn.cars24.com/qa/cms/2025/01/08/a62a0f06-7cd5-4b1c-9366-b71bc0f22203Image_Servicing_Hero%20%281%29.png"
                },
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 2,
            "pageSection": {
                "id": 1931,
                "name": "servicingHomePageDesktopBrands",
                "title": "Car brands we service",
                "subTitle": "Check out all the popular brands",
                "sectionType": "ContentWithImage",
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 3,
            "pageSection": {
                "id": 1932,
                "name": "servicingHomePageDesktopTypesOfServices",
                "title": "Types of services",
                "subTitle": "Dedicated advisors, expert care, anywhere",
                "sectionType": "Content",
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 4,
            "pageSection": {
                "id": 1933,
                "name": "servicingHomePageDesktopHowItWorksSection",
                "title": "How it works",
                "sectionType": "Grid",
                "sectionData": [
                    {}
                ],
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 5,
            "pageSection": {
                "id": 1934,
                "name": "servicingHomePageDesktopBenefits",
                "title": "Benefits of choosing CARS24 for car service",
                "sectionType": "Grid",
                "subSections": [
                    {
                        "id": 1936,
                        "name": "servicingHomePageDesktopBenefitsSubsection",
                        "title": "Servicing Home Page Desktop Benefits",
                        "sectionType": "WithBoxGrid",
                        "sectionData": [
                            {
                                "image": {
                                    "id": 1440,
                                    "url": "https://cdn.cars24.com/qa/cms/2025/01/07/b45c6959-031e-4c3c-93fa-0be8cd90f4e1Group%20639162.png",
                                    "caption": null,
                                    "alternativeText": "same_day_service"
                                },
                                "title": "Same-day service",
                                "description": "We complete the car service on the same day of pick-up. With CARS24, car servicing has become easy, quick and hassle free."
                            },
                            {
                                "image": {
                                    "id": 1442,
                                    "url": "https://cdn.cars24.com/qa/cms/2025/01/07/72ae9599-8b5f-438f-ab87-ec205cc24339Group%20639162%20%282%29.png",
                                    "caption": null,
                                    "alternativeText": "certified_technicians"
                                },
                                "title": "Certified technicians",
                                "description": "Our car maintenance technicians are well-trained, highly skilled, and certified to ensure that they can fix any brand & model."
                            },
                            {
                                "image": {
                                    "id": 1439,
                                    "url": "https://cdn.cars24.com/qa/cms/2025/01/07/78c26f4f-c019-4c96-8542-faeb4ea38bb4Group%20639162%20%281%29.png",
                                    "caption": null,
                                    "alternativeText": "free_pick_up_drop"
                                },
                                "title": "Free pick-up and drop ",
                                "description": "CARS24 car service offers free pick and drop of your beloved car from your desired location and time."
                            },
                            {
                                "image": {
                                    "id": 1441,
                                    "url": "https://cdn.cars24.com/qa/cms/2025/01/07/82b108c9-9de7-449f-985e-f8a1dc6b840fGroup%20639162%20%283%29.png",
                                    "caption": null,
                                    "alternativeText": "state_of_art_facility"
                                },
                                "title": "State-of-the-art facility",
                                "description": "CARS24 car service centers in Dubai are equipped with the best and the latest technology, machines, and computers required for servicing your car"
                            }
                        ],
                        "subsectionOrder": 1,
                        "buNames": [],
                        "statsig": false
                    }
                ],
                "sectionData": [
                    {
                        "image": {
                            "id": 1443,
                            "url": "https://cdn.cars24.com/qa/cms/2025/01/07/3449093e-e6a8-45e8-a2f4-c3ed8bb66d19Vector.png",
                            "caption": null,
                            "alternativeText": "benefits_guarantee"
                        },
                        "title": "100% Assured service quality",
                        "subTitle": "Top quality car servicing in Dubai at the best prices. With certified technicians and car experts.",
                        "description": "Satisfaction guaranteed!"
                    }
                ],
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 6,
            "pageSection": {
                "id": 1943,
                "name": "servicingHomePageDesktopRequestCallbackSection",
                "title": "Request Call back",
                "sectionType": "Banner",
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 7,
            "pageSection": {
                "id": 1944,
                "name": "servicingHomePageDesktopFaq",
                "title": "Frequently asked questions",
                "sectionType": "Faq",
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 8,
            "pageSection": {
                "id": 1945,
                "name": "servicingHomePageDesktopLearnMoreSection",
                "title": "Learn more about CARS24 car servicing",
                "sectionType": "SEOContentSection",
                "sectionData": [
                    {}
                ],
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        }
    ],
    "globalCities": null
};
