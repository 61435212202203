/* eslint-disable new-cap */
/* eslint-disable max-params */
import Types from "./types";
// import { Promise } from "bluebird";
import { CheckoutAEService as CheckoutService, CFService } from "../../../service";
import { UsedCarService } from "../../../service";
import { CHECKOUT_STEP_KEY_DESKTOP } from "../../../constants/ae/checkout-constants";
import { SlotPlannerService } from "../../../service";
import { API_SOURCES } from "../../../constants/api-constants.js";
import { reducerConstants } from "../../../constants/reducer-constant.js";

const setUserPersonalDetail = (data) => ({
    type: Types.SETUSER_PERSONAL_DETAIL,
    userPersonalDetail: data
});

const setStep = ({ currentStep, tabs }) => {
    return {
        type: Types.SET_CURRENT_STEP,
        currentStep,
        tabs
    };
};

const setFinanceData = (data) => {
    return {
        type: Types.SET_FINANCE_DATA,
        data
    };
};

const setPersonalDetailSuccessMessage = ({ successMessage }) => {
    return {
        type: Types.SET_PERSONAL_DETAIL_MESSAGE_FORM_ACTIVE,
        successMessage
    };
};

const setLoanTenureValue = ({ loanTenure }) => {
    return {
        type: Types.SET_LOAN_TENURE_VALUE,
        loanTenure
    };
};

const setCreditScoreValue = ({ creditScoreValue }) => {
    return {
        type: Types.SET_CREDIT_SCORE_VALUE,
        creditScoreValue
    };
};

const setDownPaymentValue = ({ downPaymentValue }) => {
    return {
        type: Types.SET_DOWNPAYMENT_VALUE,
        downPaymentValue
    };
};

const setMonthlyEmiValue = ({ monthlyEmiValue }) => {
    return {
        type: Types.SET_MONTHLY_EMI_VALUE,
        monthlyEmiValue
    };
};

const setOrder = ({
    bookingAmount,
    bookingConfirmDate,
    bookingExpireDate,
    bookingType,
    bookingVariables,
    chargeDetails,
    country,
    checkoutState,
    createdDate,
    currency,
    deliveryAddress,
    deliveryMode,
    financeOpted,
    financeTerms,
    id,
    orderId,
    pickupStoreId,
    pickupTime,
    status,
    claimedCoupons,
    storeId,
    totalAmount,
    userId,
    multipleBookingCase,
    vehiclePrice,
    vehicleType,
    isRefundableHomeBc,
    latestOffer
}) => {
    return {
        type: Types.SET_ORDER,
        bookingAmount,
        bookingConfirmDate,
        bookingExpireDate,
        bookingType,
        bookingVariables,
        chargeDetails,
        country,
        checkoutState,
        claimedCoupons,
        createdDate,
        currency,
        deliveryAddress,
        deliveryMode,
        financeOpted,
        financeTerms,
        id,
        orderId,
        multipleBookingCase,
        pickupStoreId,
        pickupTime,
        status,
        storeId,
        totalAmount,
        userId,
        vehiclePrice,
        vehicleType,
        isRefundableHomeBc,
        latestOffer
    };
};

const updateBookingTypeSuccess = (data) => ({
    type: Types.UPDATE_BOOKING_TYPE_SUCCESS,
    data
});

const updateBookingType = (orderId, bookingType, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateBookingType(orderId, bookingType, token).then((response) => {
            dispatch(updateBookingTypeSuccess(response.data));
            resolve();
        }).catch(error => {
            reject(error);
        });
    });
};

const initiateNexOrderPayment = ({ orderId, bookingType, payload, token }) => (_dispatch, getState) => {
    const { user: { gaId } } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.initiateNexOrderPayment({ orderId, bookingType, payload, token, gaId }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const initiateExOrderPayment = (orderId, payload, token) => (_dispatch, getState) => {
    const { user: { gaId } } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.initiateExOrderPayment(orderId, payload, token, gaId).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const setKycScreen = (data) => {
    return {
        type: Types.SET_KYC_SCREEN,
        data
    };
};

const setKycDone = ({ isKycDone }) => {
    return {
        type: Types.SET_KYC_DONE,
        isKycDone
    };
};

const removeCoupon = (orderId, token, params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.removeCoupon(orderId, token, params)
            .then(response => {
                dispatch(setOrder(response.data));
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const applyCoupon = ({ orderId, couponCode, userId, gaId, secureToken }) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.applyCoupon(orderId, { couponCode, userId, gaId }, secureToken)
            .then((response) => {
                dispatch(setOrder(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

const setFinanceSelectedValue = ({ financeSelected }) => {
    return {
        type: Types.SET_FINANCE_SELECTED,
        financeSelected
    };
};

const setMinMaxDownPaymentValue = ({ minDownpaymentValue, maxDownpaymentValue }) => {
    return {
        type: Types.SET_MIN_MAX_DOWNPAYMENT_VALUE,
        minDownpaymentValue,
        maxDownpaymentValue
    };
};

const setMinMaxEmiValue = ({ minEmiValue, maxEmiValue }) => {
    return {
        type: Types.SET_MIN_MAX_EMI_VALUE,
        minEmiValue,
        maxEmiValue
    };
};

const getOrderDetailInit = (data) => ({
    type: Types.GET_ORDER_DETAIL_INIT,
    data
});

const getOrderDetailSuccess = (data) => ({
    type: Types.GET_ORDER_DETAIL_SUCCESS,
    data
});

const getOrderDetailFailure = (error) => ({
    type: Types.GET_ORDER_DETAIL_FAILURE,
    error
});

const getOrderDetail = (appointmentId, token) => (dispatch) => {
    dispatch(getOrderDetailInit());
    return new Promise((resolve, reject) => {
        CheckoutService.getOrderSummary(appointmentId, token)
            .then((response) => {
                dispatch(getOrderDetailSuccess(response.data));
                resolve(response.data);
                // const financeTab = response.data.financeOpted ? FINANCE_TAB.GetFinancing : FINANCE_TAB.PayOnDelivery;
                // // eslint-disable-next-line no-use-before-define
                // dispatch(setFinancingTab(financeTab));
            })
            .catch(error => {
                dispatch(getOrderDetailFailure(error));
                reject(error);
            });
    });
};

const createOrder = (params, token) => (dispatch) => {
    dispatch(getOrderDetailInit());
    return new Promise((resolve, reject) => {
        CheckoutService.createOrder(params, token, "WebApp")
            .then(response => {
                dispatch(getOrderDetailSuccess(response.data));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(getOrderDetailFailure(error));
                reject(error);
            });
    });
};

const saveUserConsent = (mobile, params) => () => {
    return new Promise((resolve, reject) => {
        UsedCarService.saveUserConsent(mobile, params)
            .then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
    });
};

const updateFinancePersonalDetail = (params, orderId, token) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateFinancePersonalDetail(params, orderId, token)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const updateFinanceDetail = (order, orderId, token) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateFinanceDetail(order, orderId, token)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const getPincodeData = (appointmentId, pincode, token, userSelectedPinCode) => () => {
    return new Promise((resolve, reject) => {
        UsedCarService.getPickupLocation(appointmentId, pincode, token, userSelectedPinCode).then((data) => {
            resolve(data.data.data);
        }).catch(error => {
            reject(error);
            return error;
        });
    });
};

const updateDeliveryMode = (order, orderId, token) => () => {
    return new Promise((resolve) => {
        CheckoutService.updateDeliveryMode(order, orderId, token).then((data) => {
            resolve(data);
        });
    });
};

const updateOrderBooking = (orderId, token) => () => {
    return new Promise((resolve) => {
        CheckoutService.updateOrderBooking(orderId, token).then((data) => {
            resolve(data);
        });
    });
};

const setKycData = (data) => ({
    type: Types.SET_KYC_DATA,
    data
});

const setSelectedFinancingData = ({ loanTenure }) => ({
    type: Types.SET_SELECTED_FINANCING_DATA,
    loanTenure
});

const setOrderId = ({ orderId }) => ({
    type: Types.SET_ORDER_ID,
    orderId
});

const initiateBookingPayment = ({ orderId, payload, token }) => (_dispatch, getState) => {
    const { user: { gaId } } = getState();
    const params = { type: "NEX", gaId };
    return new Promise((resolve, reject) => {
        CheckoutService.initiateSeamlessBookingPayment(orderId, payload, token, params).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const setOrderCreatedDateTime = ({ orderCreatedDatetime }) => ({
    type: Types.SET_ORDER_CREATED_DATE_TIME,
    orderCreatedDatetime
});

const setDeliveryMode = ({ deliveryMode }) => ({
    type: Types.SET_DELIVERY_MODE,
    deliveryMode
});

const setFinanceChargeList = ({ financeChargeList }) => ({
    type: Types.SET_FINANCE_CHARGE_LIST,
    financeChargeList
});

const setChargeDetailsList = (chargeDetailsList) => ({
    type: Types.SET_CHARGE_LIST,
    chargeDetailsList
});

const setUserPickupLocation = (userPickupLocation) => ({
    type: Types.SET_USER_PICKUP_LOCATION,
    userPickupLocation
});

const setFinancingTab = (financingTab) => ({
    type: Types.SET_FINANCING_TAB,
    financingTab
});

const setFinancePersonalDetails = ({ nameOnPanCard, dateOfBirth, gender, pinCode, panNumber }) => ({
    type: Types.SET_FINANCE_PERSONAL_DETAILS,
    nameOnPanCard,
    dateOfBirth,
    gender,
    pinCode,
    panNumber
});

const setFinanceUpdatedData = ({
    creditScore,
    downPayment,
    emi,
    tenure,
    chargeList,
    totalLoanAmount
}) => ({
    type: Types.SET_FINANCE_UPDATED_DATA,
    creditScore,
    downPayment,
    emi,
    tenure,
    chargeList,
    totalLoanAmount
});

const fetchFinance = (vehiclePrice, carYear, financeEligibility) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CFService.fetchFinanceDetailAE(vehiclePrice, carYear, financeEligibility)
            .then(response => {
                const { data } = response;
                dispatch(setFinanceData(data));
                dispatch(setFinanceUpdatedData({
                    creditScore: data.defaultRoi,
                    downPayment: data.defaultDownPaymentValue,
                    emi: data.defaultMonthlyEmiValue,
                    tenure: data.defaultLoanTenureValue,
                    chargeList: null,
                    totalLoanAmount: data.defaultLoanAmount
                }));
                resolve(response.data);
            })
            .catch(err => reject(err));
    });
};

const setData = ({ type, data }, { stepper }) => dispatch => {
    switch (type) {
        case CHECKOUT_STEP_KEY_DESKTOP.Financing:
            dispatch(setSelectedFinancingData({
                loanTenure: data.loanTenure,
                stepper
            }));
            break;
    }
};

const setDeliveryData = (data) => ({
    type: Types.SET_DELIVERY_DATA,
    data
});

const initiateOrderPayment = (orderId, payload, token) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.initiateOrderPayment(orderId, payload, token).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getPaymentStatus = (orderId, paymentId, token) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.getPaymentStatus(orderId, paymentId, token).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const resetCheckoutData = () => dispatch => {
    dispatch({ type: reducerConstants.CHECKOUT });
};

const setTradeInChoice = (data) => dispatch => {
    dispatch({ type: Types.TRADE_IN_CHOICE, data });
};

const fetchEmiratesListSuccess = (data) => ({
    type: Types.FETCH_EMIRATES_LIST_SUCCESS,
    data
});

const fetchEmiratesListFailure = (error) => ({
    type: Types.FETCH_EMIRATES_LIST_FAILURE,
    error
});

const fetchEmiratesList = (slotType = "TEST_DRIVE") => (dispatch) => {
    return new Promise((resolve, reject) => {
        SlotPlannerService.getEmiratesList(slotType).then((response) => {
            dispatch(fetchEmiratesListSuccess(response.data));
            resolve(response.data);
        }).catch((error) => {
            dispatch(fetchEmiratesListFailure(error));
            reject(error);
        });
    });
};

const fetchTestDriveLocationsSuccess = (data) => ({
    type: Types.FETCH_TEST_DRIVE_LOCATIONS_SUCCESS,
    data
});

const fetchTestDriveLocationsFailure = (error) => ({
    type: Types.FETCH_TEST_DRIVE_LOCATIONS_FAILURE,
    error
});

const fetchTestDriveLocations = (params, tokenPassed) => (dispatch, getState) => {
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.getTestDriveLocations(params, tokenPassed || secureToken).then((response) => {
            dispatch(fetchTestDriveLocationsSuccess(response.data));
            resolve(response.data);
        }).catch((error) => {
            dispatch(fetchTestDriveLocationsFailure(error));
            reject(error);
        });
    });
};

// C2C Checkout : START
const submitCarOfferInit = (withLoader = true) => ({
    type: Types.SUBMIT_CAR_OFFER_INIT,
    withLoader
});

const submitCarOfferSuccess = (data) => ({
    type: Types.SUBMIT_CAR_OFFER_SUCCESS,
    data
});

const submitCarOfferFailure = () => ({
    type: Types.SUBMIT_CAR_OFFER_FAILURE
});

const submitCarOffer = (payLoad, tokenPassed, withLoader = true) => (dispatch, getState) => {
    const { user: { secureToken } } = getState();
    dispatch(submitCarOfferInit(withLoader));
    return new Promise((resolve, reject) => {
        CheckoutService.submitOfferC2c(payLoad, tokenPassed || secureToken, API_SOURCES.WEBAPP)
            .then((response) => {
                const orderData = response?.data || {};
                dispatch(submitCarOfferSuccess(orderData));
                resolve(orderData);
            })
            .catch((error) => {
                dispatch(submitCarOfferFailure());
                reject(error);
            });
    });
};

const acceptCarOfferInit = () => ({
    type: Types.ACCEPT_CAR_OFFER_INIT
});

const acceptCarOfferSuccess = (data) => ({
    type: Types.ACCEPT_CAR_OFFER_SUCCESS,
    data
});

const acceptCarOfferFailure = () => ({
    type: Types.ACCEPT_CAR_OFFER_FAILURE
});

const updateUserEnteredOfferPrice = (offerValue) => ({
    type: Types.UPDATE_USER_ENTERED_OFFER_PRICE,
    data: offerValue
});

const updateShowMakeAnOfferLoader = (data) => ({
    type: Types.UPDATE_SHOW_MAKE_AN_OFFER_LOADER,
    data
});

const updateCounterOfferClickedStatus = (data) => ({
    type: Types.UPDATE_COUNTER_OFFER_CLICKED_STATUS,
    data
});

const updateUserEnterOfferPriceErrorStatus = (data) => ({
    type: Types.UPDATE_USER_ENTERED_OFFER_PRICE_ERROR_STATUS,
    data
});

const acceptCarOffer = (offerId, payLoad, secureToken) => (dispatch) => {
    dispatch(acceptCarOfferInit());
    return new Promise((resolve, reject) => {
        CheckoutService.acceptOfferC2c(offerId, payLoad, secureToken, API_SOURCES.WEBAPP)
            .then((response) => {
                const orderData = response?.data || {};
                dispatch(acceptCarOfferSuccess(orderData));
                resolve(orderData);
            })
            .catch((error) => {
                dispatch(acceptCarOfferFailure());
                reject(error);
            });
    });
};
// C2C Checkout : END

export {
    setUserPersonalDetail,
    setStep,
    setData,
    setPersonalDetailSuccessMessage,
    setOrderCreatedDateTime,
    fetchFinance,
    setFinanceData,
    setLoanTenureValue,
    setCreditScoreValue,
    getPincodeData,
    applyCoupon,
    removeCoupon,
    setDownPaymentValue,
    setMonthlyEmiValue,
    getOrderDetail,
    updateFinancePersonalDetail,
    setDeliveryMode,
    setFinancingTab,
    setMinMaxDownPaymentValue,
    setMinMaxEmiValue,
    setFinanceSelectedValue,
    setFinancePersonalDetails,
    createOrder,
    setOrderId,
    setOrder,
    updateFinanceDetail,
    updateDeliveryMode,
    updateOrderBooking,
    setFinanceUpdatedData,
    setFinanceChargeList,
    setKycDone,
    setKycData,
    setChargeDetailsList,
    setUserPickupLocation,
    saveUserConsent,
    initiateBookingPayment,
    setKycScreen,
    setDeliveryData,
    initiateOrderPayment,
    getPaymentStatus,
    resetCheckoutData,
    updateBookingType,
    initiateNexOrderPayment,
    initiateExOrderPayment,
    setTradeInChoice,
    fetchEmiratesList,
    fetchTestDriveLocations,
    // C2C Checkout : START,
    submitCarOffer,
    acceptCarOffer,
    updateUserEnteredOfferPrice,
    updateShowMakeAnOfferLoader,
    updateCounterOfferClickedStatus,
    updateUserEnterOfferPriceErrorStatus

    // C2C Checkout : END
};
