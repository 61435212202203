/* eslint-disable max-params */
import qs from "querystring";
export default (api) => {

    const fetchAiRecommendedCars = (params, token) => {
        return api.get(`/v1/vehicle/ai-recommended`, {
            params, headers: {
                Authorization: token ? `Bearer ${token}` : ""
            }
        });
    };

    const fetchHubLocations = (params) => {
        return api.get(`/api/v1/location-screen${params ? `?${qs.stringify(params)}` : ""}`);
    };

    const getBiDataForC2COffers = (appId) => {
        return api.get(`/api/v1/stats/count/${appId}`);
    };

    const fetchStatsigDynamicConfig = (configName) => {
        return api.get(`/api/v1/statsig/dynamic-config?configName=${configName}`);
    };

    return {
        fetchAiRecommendedCars,
        fetchHubLocations,
        getBiDataForC2COffers,
        fetchStatsigDynamicConfig
    };
};
