import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FooterV3 from "./component";
import { toggleLocationPicker, updateLocationScreen } from "../location-picker-popup/actions";

const mapStateToProps = ({
    footer: { data },
    location: { selectedCity },
    config: { footerSection }
}) => ({
    data,
    selectedCity,
    footerSection
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    toggleLocationPickerConnect: toggleLocationPicker,
    updateLocationScreenConnect: updateLocationScreen
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FooterV3));
