/* eslint-disable complexity */
/* eslint-disable max-statements */
import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { appUrl, getLogoURL } from "../../../constants/url-constants";
import templateStringInterpolate from "../../../utils/helpers/template-string-interpolate";
import getLowerCase from "../../../utils/helpers/get-lower-case";
import { DEFAULT_AE_CITY_NAME, NUMBER } from "../../../constants/app-constants";
import { FILTER_ENTITIES } from "../../../utils/filters-v2/constants";
import dashedLowercase from "../../../utils/helpers/dashed-lowercase";

// Utilities
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import { useLocation, useRouteMatch } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const MetaTagsCarListingV2 = ({ metaContent = {}, cityName, content = [], imageHost, appliedFilters }) => {
    const match = useRouteMatch();
    const location = useLocation();

    const baseUrl = appUrl();
    const { url } = match;
    const {
        title,
        description,
        keywords,
        params = {}
    } = metaContent || {};
    const metaTitle = templateStringInterpolate(title, params);
    const metaDescription = templateStringInterpolate(description, params);
    const metaKeywords = templateStringInterpolate(keywords, params);

    const selectedFilters = Array.isArray(appliedFilters) && !!appliedFilters.length && appliedFilters[NUMBER.ZERO];
    const { optionKey = "", min = NUMBER.ZERO, max = NUMBER.ZERO, filterKey = "", subOptionKey = "", isSubFacetFullySelected = false } = selectedFilters;
    const queryParams = parseLocationSearchParams(location.search);
    const isSeoPath = !Object.keys(queryParams)?.length;
    const isGlobalUaeUrl = (url === "/buy-used-cars-uae/" || url === "/buy-used-cars-uae");

    const getCarDetailUrl = (contents) => {
        const { make, model, year, city, appointmentId } = contents;
        const urls = ["buy", "used"];
        const carText = (`${make}-${model}` || "");
        if (carText.includes("Suzuki")) carText.replace("Suzuki ", "");
        urls.push(carText.split(" ").join("-"));
        urls.push(year);
        urls.push("cars");
        urls.push((city || "").split(" ").join("-"));
        urls.push(appointmentId);
        const normalizedURL = urls.join("-");
        return {
            absoluteURL: getLowerCase(`${appUrl()}/${normalizedURL}/`)
        };
    };

    const listingSchema = JSON.stringify({
        "@context": "https://schema.org/",
        "@graph": {
            "@type": "ItemList",
            "itemListElement": content.map((item, index) => {
                const carFullName = `${item?.make || ""} ${item?.model || ""} ${item?.year || ""}`;
                const { absoluteURL } = getCarDetailUrl(item);
                const owner = item.ownerNumber ? `${item.ownerNumber} owner,` : "";
                return {
                    "@type": "ListItem",
                    "name": carFullName,
                    "Position": index + 1,
                    "url": absoluteURL,
                    "image": `${imageHost}${item?.mainImage?.path}`,
                    "description": `Buy ${item.year} ${item?.make || ""} ${item?.model || ""} ${item.odometerReading} KM driven, ${owner} ${item.fuelType} Certified car with 3 months warranty.`
                };
            })
        }
    });

    const getFilterName = () => {
        if (FILTER_ENTITIES.PRICE === filterKey) {
            if (min === NUMBER.ZERO) {
                return `Under ${max}`;
            } else if (min >= NUMBER.SIXTY_FIVE_THOSAND) {
                return `Above ${min}`;
            } else {
                return `${min}-${max}`;
            }
        } else {
            return optionKey;
        }
    };

    const schemaListItems = [
        {
            "@type": "ListItem",
            "position": "1",
            "name": "Home",
            "item": `${appUrl()}/`
        },
        {
            "@type": "ListItem",
            "position": "2",
            "name": "Used Cars in UAE",
            ...(!isGlobalUaeUrl && { "item": `${appUrl()}/buy-used-cars-uae/` })
        },
        ...(!isGlobalUaeUrl && {
            "@type": "ListItem",
            "position": "3",
            "name": `Used Cars In ${cityName || DEFAULT_AE_CITY_NAME}`,
            ...(filterKey && { "item": `${appUrl()}/buy-used-cars-${dashedLowercase(cityName)}/` })
        }),
        ...(getFilterName() && {
            "@type": "ListItem",
            "position": "4",
            "name": `Used ${getFilterName()} Cars in ${cityName || DEFAULT_AE_CITY_NAME}`,
            ...((FILTER_ENTITIES.MAKE === filterKey && subOptionKey && !isSubFacetFullySelected) && {
                "item": `${appUrl()}/buy-used-${dashedLowercase(optionKey)}-cars-${dashedLowercase(cityName)}/`
            })
        }),
        ...((FILTER_ENTITIES.MAKE === filterKey) && subOptionKey && !isSubFacetFullySelected && {
            "@type": "ListItem",
            "position": "5",
            "name": `Used ${optionKey} ${subOptionKey} Cars in ${cityName || DEFAULT_AE_CITY_NAME}`
        })
    ];
    const finalSchemaListItems = schemaListItems.filter((item) => item !== "" && item !== false);

    const schema = JSON.stringify(
        {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": finalSchemaListItems
        }
    );

    return (
        <Helmet script={[{
            type: "application/ld+json",
            innerHTML: schema
        }, {
            type: "application/ld+json",
            innerHTML: listingSchema
        }
        ]}>
            <meta name="ROBOTS" content={isSeoPath ? "INDEX,FOLLOW" : "NOINDEX,NOFOLLOW"} />
            <title>{metaTitle}</title>
            <meta name="title" content={metaTitle} />
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={metaKeywords} />
            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:url" content={`${baseUrl}${url}`} />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Cars24" />
            <meta property="og:image" content={getLogoURL()} />
            <link href={getLowerCase(`${appUrl()}${location.pathname.slice(-1) === "/" ? location.pathname : `${location.pathname}/`}`)} rel="canonical" />
        </Helmet>
    );
};
const mapStateToProps = ({
    config: { ratingInfo = {} } = {}
}) => ({
    ratingInfo
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

MetaTagsCarListingV2.propTypes = {
    metaContent: PropTypes.object,
    cityName: PropTypes.string.isRequired,
    content: PropTypes.array,
    imageHost: PropTypes.string,
    appliedFilters: PropTypes.array
};

export default connect(mapStateToProps, mapDispatchToProps)(MetaTagsCarListingV2);
