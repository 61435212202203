
import { CFService, cmsService, ListingService } from "../../../service";
import Types from "./types";

const pageSlug = "car-servicing-desktop";
const seoPageSlug = "servicing";

const fetchPageContentSuccess = (data) => ({
    type: Types.FETCH_PAGE_CONTENT_SUCCESS,
    pageContent: data?.pageContent || {},
    seoContent: data?.seoContent || {},
    isSSR: data?.isSSR || false
});

const fetchServiceDescriptionSuccess = (data) => ({
    type: Types.FETCH_SERVICE_DESCRIPTION_SUCCESS,
    data
});

const fetchPageContent = () => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            const pageContentResponse = await cmsService.fetchPageContent(pageSlug);
            const seoContentResponse = await ListingService.fetchSeoContent(seoPageSlug);
            const data = {pageContent: {...pageContentResponse?.data?.data, isDefaultConfig: false}, seoContent: seoContentResponse?.data, isSSR: true};
            dispatch(fetchPageContentSuccess(data));
            resolve(data);
        } catch (err) {
            reject(err);
        }
    });
};

const fetchServiceDescription = () => (dispatch, getState) => {
    const { user: { secureToken = "" } } = getState() || {};
    return new Promise((resolve, reject) => {
        CFService.getServiceDescriptionV2(secureToken)
            .then((response) => {
                const data = response?.data || [ ];
                dispatch(fetchServiceDescriptionSuccess(data));
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export {
    fetchPageContent,
    fetchServiceDescription
};
