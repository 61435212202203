import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ViewMoreServicesPopup from "./component";
import { fetchServiceDescription } from "../car-servicing-home-page/actions";

const mapStateToProps = ({
    carServicing: {
        seoContent: {
            servicingPageContent: {
                typesOfServiceSection
            } = {}
        } = {},
        tofServices = {}
    }
}) => ({ typesOfServiceSection, tofServices });

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchServiceDescriptionConnect: fetchServiceDescription
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ViewMoreServicesPopup);
