// import { resettableReducer } from "reduxsauce";
import { reducerConstants } from "../constants/reducer-constant.js";

import userReducer from "../components/ae.configuration/reducers";
import carListReducer from "../components/ae.desktop/car-listing/reducers";
import carDetailsReducer from "../components/ae.desktop/car-details/reducers";
import filtersReducer from "../components/ae.desktop/filters/reducers";
import configReducer from "../components/ae.desktop/config-ssr/reducers";
import locationReducer from "../components/ae.desktop/header-revamp/reducers";
import footerReducer from "../components/ae.desktop/footer-v2/reducers";
import checkoutReducer from "../components/ae.desktop/checkout-revamp-v2/reducers";
import tdScheduleReducer from "../components/ae.desktop/td-schedule/reducers";
import PostBcReducer from "../components/ae.desktop/post-booking-confirmation/reducers";
import recentlyViewedCarsReducer from "../components/ae.desktop/recently-viewed-car-list/reducers";
import experimentsReducer from "../components/shared/ab-experiment/reducers";
import faqReducer from "../components/ae.desktop/faq/reducers";
import toastMessageReducer from "../components/shared/toast-message/reducers";
import myBookings from "../components/ae.desktop/my-bookings/reducers";
import myWishlist from "../components/ae.desktop/my-wishlist/reducers";
import carLoan from "../components/ae.desktop/car-loan-home/reducers";
import carServicing from "../components/ae.desktop/car-servicing-home-page/reducers";
import seoCarDetails from "../components/ae.desktop/seo-car-details/reducers";
import chatIconReducer from "../components/ae.desktop/chat-icon/reducers";

// Seller Reducers
import brandsReducer from "../components/ae.desktop/seller-lead-brands/reducers";
import modelsReducer from "../components/ae.desktop/seller-lead-models/reducers";
import yearsReducer from "../components/ae.desktop/seller-lead-years/reducers";
import carEvaluationReducer from "../components/ae.desktop/seller-car-evaluation/reducers";
import carStoresReducer from "../components/ae.desktop/sell-car-appointment-store/reducers";
import { resettableReducer } from "../utils/redux/resettable-reducer.js";

export default {
    [reducerConstants.USER]: resettableReducer(reducerConstants.USER)(userReducer),
    [reducerConstants.CONFIG]: resettableReducer(reducerConstants.CONFIG)(configReducer),
    [reducerConstants.LOCATION]: resettableReducer(reducerConstants.LOCATION)(locationReducer),
    [reducerConstants.CAR_DETAILS]: resettableReducer(reducerConstants.CAR_DETAILS)(carDetailsReducer),
    [reducerConstants.CAR_LISTING]: resettableReducer(reducerConstants.CAR_LISTING)(carListReducer),
    [reducerConstants.FILTERS]: resettableReducer(reducerConstants.FILTERS)(filtersReducer),
    [reducerConstants.FOOTER]: resettableReducer(reducerConstants.FOOTER)(footerReducer),
    [reducerConstants.CHECKOUT]: resettableReducer(reducerConstants.CHECKOUT)(checkoutReducer),
    [reducerConstants.POST_BC_DETAILS]: resettableReducer(reducerConstants.POST_BC_DETAILS)(PostBcReducer),
    [reducerConstants.TD_SCHEDULE]: resettableReducer(reducerConstants.TD_SCHEDULE)(tdScheduleReducer),
    [reducerConstants.RECENTLY_VIEWED]: resettableReducer(reducerConstants.RECENTLY_VIEWED)(recentlyViewedCarsReducer),
    [reducerConstants.FAQ]: resettableReducer(reducerConstants.FAQ)(faqReducer),
    [reducerConstants.TOAST]: resettableReducer(reducerConstants.TOAST)(toastMessageReducer),
    [reducerConstants.EXPERIMENTS]: resettableReducer(reducerConstants.EXPERIMENTS)(experimentsReducer),
    // featuredCars: resettableFeaturedCarsReducer(featuredCarsReducer),
    [reducerConstants.MY_BOOKINGS]: resettableReducer(reducerConstants.MY_BOOKINGS)(myBookings),
    [reducerConstants.MY_WISHLIST]: resettableReducer(reducerConstants.MY_WISHLIST)(myWishlist),
    [reducerConstants.CAR_LOAN]: resettableReducer(reducerConstants.CAR_LOAN)(carLoan),
    [reducerConstants.CHAT_ICON]: resettableReducer(reducerConstants.CHAT_ICON)(chatIconReducer),
    // Seller
    [reducerConstants.CAR_EVALUATION]: resettableReducer(reducerConstants.CAR_EVALUATION)(carEvaluationReducer),
    [reducerConstants.BRANDS]: resettableReducer(reducerConstants.BRANDS)(brandsReducer),
    [reducerConstants.MODELS]: resettableReducer(reducerConstants.MODELS)(modelsReducer),
    [reducerConstants.YEARS]: resettableReducer(reducerConstants.YEARS)(yearsReducer),
    [reducerConstants.CAR_STORES]: resettableReducer(reducerConstants.CAR_STORES)(carStoresReducer),
    [reducerConstants.SEO_CAR_DETAILS]: resettableReducer(reducerConstants.SEO_CAR_DETAILS)(seoCarDetails),
    // Car Servicing
    [reducerConstants.CAR_SERVICING]: resettableReducer(reducerConstants.CAR_SERVICING)(carServicing)
};

