const currentEnv = process.env.HOST_ENV;

const appUrl = () => {
    const urls = {
        LOCAL: "http://localhost:5000",
        QA: "https://consumer-web-ae.qac24svc.dev",
        STAGING: "https://stage-consumer-web-ae.qac24svc.dev",
        LNOD: "https://stage-consumer-web-ae.qac24svc.dev",
        PRODUCTION: "https://www.cars24.ae"
    };
    return urls[currentEnv];
};

const sellCarApiUrl = () => {
    const urls = {
        LOCAL: "https://car-service-java.qac24svc.dev",
        QA: "https://car-service-java.qac24svc.dev",
        STAGING: "https://car-service-stage.cars24.team",
        LNOD: "https://sellerengine-qa8.ninja24.in",
        PRODUCTION: "https://car-service-java.c24.tech"
    };
    return urls[currentEnv];
};

const apiUrl = () => {
    const urls = {
        LOCAL: "https://c2c-web.qac24svc.dev",
        STAGING: "https://api-staging.qac24svc.dev",
        QA: "https://api-se-qa1.qac24svc.dev",
        LNOD: "https://sellerengine-qa8.ninja24.in",
        PRODUCTION: "https://api.cars24.com"
    };
    return urls[currentEnv];
};

const ssrApiUrl = () => {
    const urls = {
        LOCAL: "http://c2c-web.qac24svc.dev",
        STAGING: "https://api-staging.qac24svc.dev",
        QA: "http://api-se-qa1.qac24svc.dev",
        LNOD: "http://sellerengine-qa8.ninja24.in",
        PRODUCTION: "http://api.cars24.com"
    };
    return urls[currentEnv];
};

const vehicleApiUrl = () => {
    const urls = {
        LOCAL: "https://vehicle-service.qac24svc.dev",
        STAGING: "https://vehicle-service.qac24svc.dev",
        QA: "https://vehicle-service.qac24svc.dev",
        LNOD: "https://vehicle-service.qac24svc.dev",
        PRODUCTION: "https://vehicle.cars24.team"
    };
    return urls[currentEnv];
};

const b2cInventoryApiUrl = () => {
    const urls = {
        LOCAL: "https://b2c-inventory.qac24svc.dev",
        STAGING: "https://stage-b2c-inventory.qac24svc.dev",
        QA: "https://b2c-inventory.qac24svc.dev",
        LNOD: "https://b2c-inventory.qac24svc.dev",
        PRODUCTION: "https://b2c-inventory.c24.tech"
    };
    return urls[currentEnv];
};

const vehicleSSRApiUrl = () => {
    const urls = {
        LOCAL: "http://vehicle-service.qac24svc.dev",
        STAGING: "http://vehicle-service.qac24svc.dev",
        QA: "http://vehicle-service.qac24svc.dev",
        LNOD: "http://vehicle-service.qac24svc.dev",
        PRODUCTION: "https://vehicle.cars24.team"
    };
    return urls[currentEnv];
};

const staticAppUrl = () => {
    const urls = {
        LOCAL: "https://staging-static.cars24.com",
        QA: "https://static.cars24.com",
        STAGING: "https://static.cars24.com",
        LNOD: "https://staging-static.cars24.com",
        PRODUCTION: "https://static.cars24.com"
    };
    return urls[currentEnv];
};

const getPrivateProductApiKey = () => {
    const urls = {
        QA: "c2c-s4583HJKH58",
        STAGING: "pp-ui-s32578KJDHS",
        PRODUCTION: "qGuMZcWGxZpgd8uSH4rgkal4v1evAlCd"
    };
    return urls[currentEnv];
};

const ppApiUrl = () => {
    const urls = {
        LOCAL: "https://pvt-product.qac24svc.dev",
        QA: "https://pvt-product.qac24svc.dev",
        STAGING: "https://pvt-product-stage.cars24.com",
        LNOD: "https://pvt-product-stage.cars24.com",
        PRODUCTION: "https://pvt-product.cars24.com"
    };
    return urls[currentEnv];
};

const c2bKeys = {
    LOCAL: "YzJiX2Zyb250ZW5kOmJBVllHRVlzODZIWDVDd01jcmo5M01DQklvOEs2b2xo",
    QA: "YzJiX2Zyb250ZW5kOkszcHJyT0cwNm1uZE41V0FMS0NTQUxuQVd3eHBXQ0hJ",
    STAGING: "YzJiX2Zyb250ZW5kOmJBVllHRVlzODZIWDVDd01jcmo5M01DQklvOEs2b2xo",
    PRODUCTION: "YzJiX2Zyb250ZW5kOko1SXRmQTk2bTJfY3lRVk00dEtOSnBYaFJ0c0NtY1h1"
};

const c2bAuthKey = () => {
    return c2bKeys[currentEnv];
};

const sellCarAuthKey = () => {
    const keys = {
        LOCAL: "Y2FyX3NlcnZpY2VfdXNlcl9xYTpxYzBhZThmNGE3YTQzNmY1ZmQyODJxYTFlM2JlNTZjODFiOTM3OQ==",
        QA: "Y2FyX3NlcnZpY2VfdXNlcl9xYTpxYzBhZThmNGE3YTQzNmY1ZmQyODJxYTFlM2JlNTZjODFiOTM3OQ==",
        STAGING: "YWRtaW46cGFzc3dvcmQ=",
        PRODUCTION: "Y2FyX3NlcnZpY2VfdXNlcjpwZDBhZThmNGE3YTQzNmY1ZmQyOHByb2QyMWUzYmU1NmNiOTM3OQ=="
    };
    return keys[currentEnv];
};

const lmsAuthKey = () => {
    const keys = {
        LOCAL: "8a223df3-9fbd-426e-aa2f-e79bdcf60f61",
        QA: "8a223df3-9fbd-426e-aa2f-e79bdcf60f61",
        STAGING: "01e72f78-24f1-434a-ab6b-3342d75c352e",
        PRODUCTION: "b78f06db-d0d7-4c60-b1a6-fc153d2527be"
    };
    return keys[currentEnv];
};

const getLogoURL = () => {
    const domain = staticAppUrl();
    return `${domain}/cars24/images/cars-logo.png?v=1`;
};

const exchangeApiUrl = () => {
    const urls = {
        LOCAL: "https://consumer-web-ae.qac24svc.dev",
        QA: "https://exchange-service-qa.ninja24.in",
        STAGING: "https://exchange-service-stage.cars24.team",
        LNOD: "https://exchange-service-qa.ninja24.in",
        PRODUCTION: "https://exchange-service.cars24.team"
    };
    return urls[currentEnv];
};

const checkoutApiUrl = () => {
    const urls = {
        LOCAL: "https://b2c-uae-oms.qac24svc.dev",
        QA: "https://b2c-uae-oms.qac24svc.dev",
        STAGING: "https://stage-b2c-uae-oms.qac24svc.dev",
        LNOD: "https://b2c-uae-oms.qac24svc.dev",
        PRODUCTION: "https://b2c-uae-oms.c24.tech"
    };
    return urls[currentEnv];
};

const listingApiUrl = () => {
    const urls = {
        LOCAL: "https://listing-service.qac24svc.dev",
        QA: "https://listing-service.qac24svc.dev",
        STAGING: "https://stage-listing-service.qac24svc.dev",
        LNOD: "https://listing-service.qac24svc.dev",
        PRODUCTION: "https://listing-service.c24.tech"
    };
    return urls[currentEnv];
};
const listingSSRApiUrl = () => {
    const urls = {
        LOCAL: "http://listing-service.qac24svc.dev",
        QA: "http://listing-service.qac24svc.dev",
        STAGING: "http://stage-listing-service.qac24svc.dev",
        LNOD: "http://listing-service.qac24svc.dev",
        PRODUCTION: "http://listing-service"
        // PRODUCTION: "http://listing-service.c24.tech"
    };
    return urls[currentEnv];
};
const leadApiUrl = () => {
    const urls = {
        LOCAL: "https://lead-collector-stage.cars24.team",
        QA: "https://stage-lead-collector-service.qac24svc.dev",
        STAGING: "https://stage-lead-collector-service.qac24svc.dev",
        LNOD: "https://lead-collector-stage.cars24.team",
        PRODUCTION: "https://lead-collector.cars24.team"
    };
    return urls[currentEnv];
};

const b2cMasterDataSSRApi = () => {
    const urls = {
        LOCAL: "http://b2c-masterdata.qac24svc.dev",
        STAGING: "http://stage-b2c-masterdata.qac24svc.dev",
        QA: "http://b2c-masterdata.qac24svc.dev",
        LNOD: "http://b2c-masterdata.qac24svc.dev",
        PRODUCTION: "http://b2c-masterdata"
        // PRODUCTION: "http://b2c-masterdata.c24.tech"
    };
    return urls[currentEnv];
};

const b2cMasterDataApi = () => {
    const urls = {
        LOCAL: "https://b2c-masterdata.qac24svc.dev",
        STAGING: "https://stage-b2c-masterdata.qac24svc.dev",
        QA: "https://b2c-masterdata.qac24svc.dev",
        LNOD: "https://b2c-masterdata.qac24svc.dev",
        PRODUCTION: "https://b2c-masterdata.c24.tech"
    };
    return urls[currentEnv];
};

const b2cMasterDataMeApi = () => {
    const urls = {
        LOCAL: "https://b2c-masterdata-me.qac24svc.dev",
        STAGING: "https://stage-b2c-masterdata-me.qac24svc.dev",
        QA: "https://b2c-masterdata-me.qac24svc.dev",
        LNOD: "https://b2c-masterdata-me.qac24svc.dev",
        PRODUCTION: "https://b2c-masterdata-me.c24.tech"
    };
    return urls[currentEnv];
};

const leadApiUrlKey = () => {
    const keys = {
        LOCAL: "6f355999-4af7-4c3a-8816-a16aaf2d8994",
        QA: "6f355999-4af7-4c3a-8816-a16aaf2d8994",
        STAGING: "6f355999-4af7-4c3a-8816-a16aaf2d8994",
        PRODUCTION: "6f355999-4af7-4c3a-8816-a16aaf2d8994"
    };
    return keys[currentEnv];
};

const cfApiUrl = () => {
    const urls = {
        LOCAL: "https://b2c-uae-cf.qac24svc.dev",
        STAGING: "https://stage-b2c-uae-cf.qac24svc.dev",
        QA: "https://b2c-uae-cf.qac24svc.dev",
        PRODUCTION: "https://b2c-uae-cf.c24.tech"
    };
    return urls[currentEnv];
};

const cfApiSSRUrl = () => {
    const urls = {
        LOCAL: "http://b2c-uae-cf.qac24svc.dev",
        STAGING: "http://stage-b2c-uae-cf.qac24svc.dev",
        QA: "http://b2c-uae-cf.qac24svc.dev",
        PRODUCTION: "http://b2c-uae-cf"
        // PRODUCTION: "http://b2c-uae-cf.c24.tech"
    };
    return urls[currentEnv];
};

const checkoutApiSsrUrl = () => {
    const urls = {
        LOCAL: "http://b2c-uae-oms.qac24svc.dev",
        QA: "https://b2c-uae-oms.qac24svc.dev",
        STAGING: "https://stage-b2c-uae-oms.qac24svc.dev",
        LNOD: "http://b2c-uae-oms.qac24svc.dev",
        PRODUCTION: "http://b2c-uae-oms"
        // PRODUCTION: "http://b2c-uae-oms.c24.tech"
    };
    return urls[currentEnv];
};

const deliveryApiUrl = () => {
    const urls = {
        LOCAL: "https://b2c-uae-delivery.qac24svc.dev",
        STAGING: "https://stage-b2c-uae-delivery.qac24svc.dev",
        QA: "https://b2c-uae-delivery.qac24svc.dev",
        LNOD: "https://b2c-uae-delivery.qac24svc.dev",
        PRODUCTION: "https://b2c-uae-delivery.c24.tech"
    };
    return urls[currentEnv];
};

const cfAuthKey = () => {
    const keys = {
        LOCAL: "2c38614a-41a9-4e47-b957-2ca5b67e8db6",
        QA: "2c38614a-41a9-4e47-b957-2ca5b67e8db6",
        STAGING: "2c38614a-41a9-4e47-b957-2ca5b67e8db6",
        PRODUCTION: "cae9508d-794b-47ca-a1ab-6ed4a00df650"
    };
    return keys[currentEnv];
};

const paymentApiUrl = () => {
    const urls = {
        LOCAL: "https://b2c-uae-payment.qac24svc.dev",
        STAGING: "https://stage-b2c-uae-payment.qac24svc.dev",
        QA: "https://b2c-uae-payment.qac24svc.dev",
        LNOD: "https://b2c-uae-payment.qac24svc.dev",
        PRODUCTION: "https://b2c-uae-payment.c24.tech"
    };
    return urls[currentEnv];
};

const c2bAppointmentUrl = () => {
    const urls = {
        LOCAL: "https://c2b-uae-appointment.qac24svc.dev",
        STAGING: "https://stage-c2b-uae-appointment.qac24svc.dev",
        QA: "https://c2b-uae-appointment.qac24svc.dev",
        LNOD: "https://c2b-uae-appointment.qac24svc.dev",
        PRODUCTION: "https://c2b-uae-appointment.c24.tech"
    };
    return urls[currentEnv];
};
const uaeApiGateway = () => {
    const urls = {
        LOCAL: "https://uae-api-gateway.qac24svc.dev",
        STAGING: "https://stage-uae-api-gateway.qac24svc.dev",
        QA: "https://uae-api-gateway.qac24svc.dev",
        PRODUCTION: "https://uae-api-gateway.c24.tech"
    };
    return urls[currentEnv];
};

const uaeC2BOms = () => {
    const urls = {
        LOCAL: "https://c2b-oms.qac24svc.dev",
        STAGING: "https://stage-c2b-oms.qac24svc.dev",
        QA: "https://c2b-oms.qac24svc.dev",
        PRODUCTION: "https://c2b-oms.c24.tech"
    };
    return urls[currentEnv];
};

const uaeC2CInventory = () => {
    const urls = {
        LOCAL: "https://c2c-uae-inventory.qac24svc.dev",
        STAGING: "https://stage-c2c-uae-inventory.qac24svc.dev",
        QA: "https://c2c-uae-inventory.qac24svc.dev",
        PRODUCTION: "https://c2c-uae-inventory.c24.tech"
    };
    return urls[currentEnv];
};

const crmApiUrl = () => {
    const urls = {
        LOCAL: "https://b2c-uae-crm.qac24svc.dev",
        STAGING: "https://stage-b2c-uae-crm.qac24svc.dev",
        QA: "https://b2c-uae-crm.qac24svc.dev",
        LNOD: "https://b2c-uae-crm.qac24svc.dev",
        PRODUCTION: "https://b2c-uae-crm.c24.tech"
    };
    return urls[currentEnv];
};

const slotPlannerApiUrl = () => {
    const urls = {
        LOCAL: "https://b2c-uae-slotplanner.qac24svc.dev",
        STAGING: "https://stage-b2c-uae-slotplanner.qac24svc.dev",
        QA: "https://b2c-uae-slotplanner.qac24svc.dev",
        LNOD: "https://b2c-uae-slotplanner.qac24svc.dev",
        PRODUCTION: "https://b2c-uae-slotplanner.c24.tech"
    };
    return urls[currentEnv];
};

const userProfileDataApi = () => {
    const urls = {
        LOCAL: "https://user-profile-me.qac24svc.dev/",
        STAGING: "https://stage-user-profile-me.qac24svc.dev/",
        QA: "https://user-profile-me.qac24svc.dev/",
        LNOD: "https://user-profile-me.qac24svc.dev/",
        PRODUCTION: "https://user-profile-me.c24.tech/"
    };
    return urls[currentEnv];
};
const couponApiUrl = () => {
    return checkoutApiUrl();
};

const cbdMerchantFormUrl = () => {
    const urls = {
        LOCAL: "https://pgt.cbuaepay.ae/PGCustomerPortal/transactionmanagement/merchantform",
        STAGING: "https://pgt.cbuaepay.ae/PGCustomerPortal/transactionmanagement/merchantform",
        QA: "https://pgt.cbuaepay.ae/PGCustomerPortal/transactionmanagement/merchantform",
        LNOD: "https://pgt.cbuaepay.ae/PGCustomerPortal/transactionmanagement/merchantform",
        PRODUCTION: "https://cbuaepay.ae/PGCustomerPortal/transactionmanagement/merchantform"
    };
    return urls[currentEnv];
};

const b2cVasApiUrl = () => {
    const urls = {
        LOCAL: "'https://b2c-uae-vas.qac24svc.dev/",
        STAGING: "https://stage-b2c-uae-vas.qac24svc.dev/",
        QA: "https://b2c-uae-vas.qac24svc.dev/",
        LNOD: "https://b2c-uae-vas.qac24svc.dev/",
        PRODUCTION: "https://b2c-uae-vas.c24.tech/"
    };
    return urls[currentEnv];
};

const b2cUaeCcApiUrl = () => {
    const urls = {
        LOCAL: "https://b2c-uae-cc.qac24svc.dev",
        STAGING: "https://stage-b2c-uae-cc.qac24svc.dev",
        QA: "https://b2c-uae-cc.qac24svc.dev",
        LNOD: "https://b2c-uae-cc.qac24svc.dev",
        PRODUCTION: "https://b2c-uae-cc.c24.tech"
    };
    return urls[currentEnv];
};

const cmsApiUrl = () => {
    const urls = {
        LOCAL: "https://super-app-backend.qac24svc.dev",
        STAGING: "https://stage-super-app-backend.qac24svc.dev",
        QA: "https://super-app-backend.qac24svc.dev",
        LNOD: "https://super-app-backend.qac24svc.dev",
        PRODUCTION: "https://superappapi.cars24.team"
    };
    return urls[currentEnv];
};

module.exports = {
    currentEnv,
    appUrl,
    apiUrl,
    ssrApiUrl,
    staticAppUrl,
    getPrivateProductApiKey,
    ppApiUrl,
    c2bAuthKey,
    getLogoURL,
    exchangeApiUrl,
    sellCarApiUrl,
    sellCarAuthKey,
    lmsAuthKey,
    vehicleApiUrl,
    vehicleSSRApiUrl,
    checkoutApiUrl,
    listingApiUrl,
    b2cMasterDataSSRApi,
    b2cInventoryApiUrl,
    leadApiUrl,
    leadApiUrlKey,
    b2cMasterDataApi,
    c2bKeys,
    cfApiUrl,
    cfApiSSRUrl,
    cfAuthKey,
    deliveryApiUrl,
    checkoutApiSsrUrl,
    paymentApiUrl,
    c2bAppointmentUrl,
    crmApiUrl,
    uaeApiGateway,
    slotPlannerApiUrl,
    uaeC2BOms,
    b2cMasterDataMeApi,
    userProfileDataApi,
    couponApiUrl,
    cbdMerchantFormUrl,
    b2cVasApiUrl,
    uaeC2CInventory,
    b2cUaeCcApiUrl,
    listingSSRApiUrl,
    cmsApiUrl
};
