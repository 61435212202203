import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

// Styles
import styles from "./styles.css";

// components
import Button from "../../shared/button";
import LazyImage from "../../shared/lazy-image";

const ServicingHomeBanner = ({
    onCtaClick = () => { },
    sectionInfo = {}
}) => {

    const {
        title = "",
        subTitle = "",
        backgroundImage: {
            url: bannerUrl = "",
            alternativeText = ""
        } = {},
        subSections = []
    } = sectionInfo || {};

    let subsectionData = [];

    if (subSections.length) {
        const { sectionData } = subSections[0];
        subsectionData = sectionData;
    }

    return (
        <React.Fragment>
            <Helmet>
                <link fetchPriority="high" rel="preload" href={bannerUrl} as={"image"} type="image/png" />
            </Helmet>
            <div styleName={"styles.bannerWrapper"} >
                <img src={bannerUrl} alt={alternativeText} />
                <div styleName={"styles.bannerContentWrapper"} >
                    <div styleName={"styles.bannerContent"} >
                        <div>
                            {title && <h1>{title}</h1>}
                        </div>
                        <div>
                            {subTitle && <h2>{subTitle}</h2>}
                        </div>
                        <div>
                            <div styleName={"styles.highlightsSection"}   >
                                {(subsectionData || []).map((subSectionInfo, idx) => {
                                    const {
                                        image: {
                                            url: imageUrl = "",
                                            alternativeText: subsectionAlternativeText = ""
                                        } = {},
                                        title: subsectionTitle = ""
                                    } = subSectionInfo || {};
                                    return (
                                        <div styleName={"styles.highlightsCard"} key={`heroBannerSubsection_${idx}`} >
                                            <LazyImage src={imageUrl} alt={subsectionAlternativeText} />
                                            {title && <p>{subsectionTitle}</p>}
                                        </div>
                                    );
                                })}
                            </div>
                            <div styleName={"styles.bannerCtaWrap"}  >
                                <Button
                                    isLoading={false}
                                    text={"BOOK YOUR SERVICE NOW!"}
                                    onClick={onCtaClick}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

ServicingHomeBanner.propTypes = {
    onCtaClick: PropTypes.func,
    sectionInfo: PropTypes.object
};

export default ServicingHomeBanner;
