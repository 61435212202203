import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarServicingHomePage from "./component";
import { fetchFaq } from "../faq/actions";
import { fetchPageContent } from "./actions";

const mapStateToProps = ({
    faq: {
        content: faqData = [ ]
    },
    carServicing: {
        isSSR: isDataLoaded,
        pageContent = {}
    }
}) => ({
    faqData,
    isDataLoaded,
    pageContent
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchFaqConnect: fetchFaq,
    fetchPageContentConnect: fetchPageContent
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarServicingHomePage);
