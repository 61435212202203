import React from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";

// Constants
import { appUrl } from "../../../constants/url-constants";

//Locale
import ServiceContractLocale from "../../../locales/mobile/service-contract";

// Utilities
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const MetaTagsCarSC = ({ location }) => {
    const baseUrl = `${appUrl()}/ae/`;
    const { meta: metaLocale } = ServiceContractLocale;
    const queryParams = parseLocationSearchParams(location.search);
    const isSeoPath = !Object.keys(queryParams)?.length;

    return (
        <Helmet>
            <title>{metaLocale.title}</title>
            <meta name="description" content={metaLocale.description} />
            <meta name="ROBOTS" content={isSeoPath ? "INDEX,FOLLOW" : "NOINDEX,NOFOLLOW"} />
            <link href={`${baseUrl}`} rel="canonical" />
            <link rel="alternate" href="https://www.cars24.com/" hrefLang="en-IN" />
            <link rel="alternate" href="https://www.cars24.com.au/" hrefLang="en-AU" />
            <link rel="alternate" href="https://www.cars24.ae/" hrefLang="en-AE" />
        </Helmet>
    );
};

const mapStateToProps = ({
    config: { ratingInfo = {} } = {}
}) => ({
    ratingInfo
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
MetaTagsCarSC.propTypes = {
    location: PropTypes.object
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MetaTagsCarSC));

