import React from "react";
import { Helmet } from "react-helmet-async";

// Components
import CarLoanHomeComponent from "../../../components/ae.desktop/car-loan-home";
import ErrorHandler from "../../../components/shared/with-error-handler";
import { appUrl } from "../../../constants/url-constants";
import { ROUTE_PATHS } from "../../../constants/routes-constants";
import carLoanLocale from "../../../locales/desktop/car-loan";

const CarLoan = () => {
    const baseUrl = `${appUrl()}/${ROUTE_PATHS.carLoan}/`;
    const { title, description, h1 } = carLoanLocale.meta;
    return (
        <React.Fragment>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="H1" content={h1} />
                <meta name="ROBOTS" content="INDEX,FOLLOW" />
                <link href={`${baseUrl}`} rel="canonical" />
            </Helmet>
            <ErrorHandler>
                <CarLoanHomeComponent />
            </ErrorHandler>
        </React.Fragment>
    );
};

export default CarLoan;
